(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('EditorPhotoBothController', EditorPhotoBothController);

    EditorPhotoBothController.$inject = ['$state', '$scope', '$q', '$stateParams', 'Convert', 'Qinius', 'WxJS', 'Gallery', 'ItemValue', 'RecommendPics', 'Theme', '$localStorage', '$timeout', 'FontManagement', '$rootScope'
        , 'ImageService', 'ClipArt', 'PlatformWechatSetting', 'UidService', 'Store', 'Article', 'CommonService', '$http', 'ThemeClassify', 'MessageNotification', 'Product',
        'entity', 'StoreWorkEditConfig', '$translate','IMAGE_SUPPORTED_MIME','MessageService','StoreStorages'];

    function EditorPhotoBothController($state, $scope, $q, $stateParams, Convert, Qinius, WxJS, Gallery, ItemValue, RecommendPics, Theme, $localStorage, $timeout, FontManagement, $rootScope
        , ImageService, ClipArt, PlatformWechatSetting, UidService, Store, Article, CommonService, $http, ThemeClassify, MessageNotification, Product,
                                       entity, StoreWorkEditConfig, $translate,IMAGE_SUPPORTED_MIME,MessageService,StoreStorages) {
        //初始化
        $(".navbar-gallery").hide();
        $(".wrap").show().addClass('no-margins');
        $(".well").css({'margin-bottom': 0});
        $scope.dWidth = document.documentElement.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});
        var vm = this;
        vm.isEdit = entity.type == 'edit' ? true : false;
        vm.storeId = $stateParams.storeId;
        vm.pid = $stateParams.pid;
        vm.aid = $stateParams.aid;
        vm.vid = $stateParams.vid;
        vm.xmlId = $stateParams.xmlId;
        vm.productLine = {};
        $scope.domains = Qinius.getDomain();
        Qinius.get({}, function (res) {
            vm.token = res.token;
            vm.tokenTime = parseInt((res.expireAt - new Date().getTime()));
        });
        PlatformWechatSetting.get({}, function (res) {
            vm.platformSetting = res.data;
        });
        vm.photoArray = [];
        vm.photoSelectAll = [];
        vm.isPhone = false;
        $scope.qiniuId = JSON.parse(localStorage.getItem("gallery")) != null ? JSON.parse(localStorage.getItem("gallery")) : [];
        $scope.status = localStorage.getItem('Production');
        vm.page1 = true;
        vm.page2 = false;
        vm.store = Store.get({id: vm.storeId});
        vm.photoAll = [];
        vm.loading = true;
        $scope.agentIos = false;
        vm.isIPhone6 = isIPhone6();
        vm.supportedMimeTypes = IMAGE_SUPPORTED_MIME;
        vm.threeLoading = false;
        vm.threeLoadingText=$translate.instant("hint.loadingText");
        vm.recommendPics = [];

        //阻止微信端长按赋值保存图片
        document.oncontextmenu = function (e) {
            //或者return false;
            e.preventDefault();
        };

        /**
         * wechat api
         */

        // var ua = navigator.userAgent.toLowerCase();
        // if(ua.match(/MicroMessenger/i)=="micromessenger") {
        //     $scope.agentIos = browserVersions();
        // }

        $q.all([$scope.domains.$promise, vm.store.$promise]).then(function () {
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            // token过期

            vm.lagnuages =[];
            if ($localStorage.lagnuages) {
                for(var i = 0 ; i<$localStorage.lagnuages.length; i++ ){
                    var lang = $localStorage.lagnuages[i];
                    var langObj = {key:lang};
                    if (lang == 'zh-cn') {
                        langObj.value = "中文";
                    }
                    if (lang == 'en') {
                        langObj.value = "English";
                    }
                    if (vm.lang == lang) {
                        vm.langValue = langObj.value;
                        langObj.selected = true;
                    }
                    vm.lagnuages.push(langObj);
                }
            }

            setTimeout(function fn() {
                Qinius.get(function (msg) {
                    vm.token = msg.token;
                    vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                });
                setTimeout(fn, vm.tokenTime);
            }, vm.tokenTime);
            if (wx) {
                var url = window.location.hash && window.location.href.slice(0, -window.location.hash.length) || window.location.href;
                // var url = "https://stage.fotavatar.com/10/143/1230321120340111/editorPhotoBoth";
                WxJS.get({url: url}, function (res) {
                    if (res.status == 200) {
                        $scope.agentIos = browserVersions();
                        var config = res.data;
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: config.appId, // 必填，公众号的唯一标识
                            timestamp: config.timestamp, // 必填，生成签名的时间戳
                            nonceStr: config.nonceStr, // 必填，生成签名的随机串
                            signature: config.signature,// 必填，签名，见附录1
                            jsApiList: ['chooseImage', 'uploadImage', 'hideMenuItems'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                        });
                        wx.ready(function () {
                            console.log('wechat api ready!');

                            // wx.hideMenuItems({
                            //     menuList: ["menuItem:copyUrl", "menuItem:share:timeline", "menuItem:share:appMessage", "menuItem:share:qq", "menuItem:share:appMessage", "menuItem:share:QZone"]
                            // });
                        });
                        wx.error(function (res) {
                            console.log(res)
                        });
                    }
                }, function () {
                    console.log("wx config error");
                });
            }

            //判断是ios or Android
            function isIphoneX() {
                return /iphone/gi.test(window.navigator.userAgent) && window.screen.width === 375 && (window.screen.height === 812 || window.screen.height == 724);
            }

            $scope.isIphoneX = isIphoneX();

            RecommendPics.getAllByCondition2({items:[{key: "cancelled", op: "=", value: "false"}],sort: ["seq,asc"]},function(res){
                if(res && res.data){
                    res.data.forEach(function(item){
                        item.identifier = $scope.domain + item.identifier;
                    })
                    vm.recommendPics = res.data;
                }
            })
            init();
            // $timeout(function () {
            //     qInit();
            // })
        });

        vm.isEditorPhotoUploadPopup = false;
        $scope.showGallery = function () {
            if(vm.typeImageData.editTip && vm.data.length == 0){
                vm.isEditorPhotoUploadPopup = true;
            }else{
                $scope.uploadPhoto(true)
            }
        }
        $scope.editorPhotoUploadPopupChange = function (){
            vm.isEditorPhotoUploadPopup = false;
            $scope.uploadPhoto(true)
        }
        vm.uploadPhotoItem=[];
        vm.isAddImg = false;
        $scope.uploadPhoto = function (type) {
            vm.isAddImg = type;
            uploadAble(StoreStorages,vm.storeId, function (res) {
                if(res.status == 200){
                    if($scope.agentIos){
                        $rootScope.$broadcast('clickAndroidUpload',true);
                    }else{
                        $timeout(function () {
                            $("#uploadImageMutiple-page0").trigger("click");
                        })
                    }
                }else{
                    if(res.status == 400){
                        threeInfo('系统出错');
                    } else {
                        threeInfo(res.data||'系统出错');
                    }
                }
            })
        }
        $scope.$on('uoloadFinished', function(event, args) {
            if(args){
                if (vm.isAddImg && vm.pictureType == 'spread' && vm.photoSelectAll && vm.photoSelectAll.length>0) {
                    if(vm.photoSelectAll && vm.photoSelectAll.length==1){
                        $scope.photoSave();
                    }else{
                        vm.page1 = false;
                        vm.page2 = true;
                    }
                }
            }
        });
        $scope.pageSortCb = function (text){
            vm.photoSelectAll = JSON.parse(text);
            vm.page1 = true;
            vm.page2 = false;
            if(vm.photoSelectAll && vm.photoSelectAll.length>0){
                $scope.photoSave();
            }
        }
        $scope.uploadPhotoCb = function (text){
            if(vm.isAddImg && text){
                if(vm.pictureType == 'single'){
                    var img = JSON.parse(text);
                    if(img && img.identifier){
                        var _page = angular.copy(vm.signalPage1);
                        _page.levels.level.forEach(function (level) {
                            if (level.imagebox) {
                                level.imagebox.bokehtype = 'None';
                                level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                                level.imagebox.image = {
                                    angle: 0,
                                    filter: null,
                                    filterResource: null,
                                    offsetX: 0,
                                    offsetY: 0,
                                    resource: {identifier: img.identifier, provider: "qiniu"},
                                    size: img.imgSize,
                                    provider: "qiniu",
                                    resourceid: img.sourceId,
                                    scale: 1,
                                    width: img.width,
                                    height: img.height,
                                    imageInfo: img.imageInfo,
                                };
                                level.imagebox.blurredrectangle = {
                                    width: '',
                                    height: '',
                                    x: '',
                                    y: '',
                                };
                                getBlurredrectangle(level.imagebox, 0);
                            }
                        })
                        _page.quantity = 1;
                        _page.uuid = uuid();
                        vm.data.push(_page)
                        getCostMethod();
                    }
                }
            }
        }

        vm.itemsPerPage = 40;//分页数量
        function loadAll(folderId) {
            if (folderId == 'isNull') {
                Gallery.storeByCondition({
                    items: [{key: "folderId", op: "isNull", value: ''}, {key: "articleId", op: "=", value: vm.aid}],
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sorts()
                }, onSuccess, onError);
            } else {
                Gallery.storeByCondition({
                    items: [{key: "folderId", op: "=", value: folderId}, {key: "articleId", op: "=", value: vm.aid}],
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sorts()
                }, onSuccess, onError);
            }
        }

        function sorts() {
            return ["id,desc"];
        }

        function onSuccess(res) {
            for (var i = 0; i < res.data.length; i++) {
                if (!res.data[i].cancelled) {
                    res.data[i].identifier = $scope.domain + res.data[i].identifier;
                    vm.photoAll.push(res.data[i])
                }
            }
            if (res.data.length < vm.itemsPerPage) {
                vm.photoCompleted = true;
            }
            getPagePhoto();
        }

        function onError(data) {
            if(data.status !== 403){
                MessageService.hint($translate.instant("editorCommon.failedToObtainThePhotoGallery"));
            }
        }

        function getPagePhoto() {
            var timeList = [];
            vm.photoArray = [];
            for (var i = 0; i < vm.photoAll.length; i++) {
                var photoCreatedDate = new Date(vm.photoAll[i].createdDate);
                if (checkExist(timeList, photoCreatedDate)) {
                    timeList.push(photoCreatedDate);
                }
            }
            //将相同日期下的照片整合
            for (var m = 0; m < timeList.length; m++) {
                var tempObj = {};
                var tempItems = [];
                for (var j = 0; j < vm.photoAll.length; j++) {
                    var createdDate = new Date(vm.photoAll[j].createdDate);
                    if (timeList[m].getFullYear() == createdDate.getFullYear() &&
                        timeList[m].getMonth() == createdDate.getMonth() &&
                        timeList[m].getDate() == createdDate.getDate()) {
                        tempItems.push(vm.photoAll[j])
                    }
                }
                var time = timeList[m].getFullYear() + '-' + (timeList[m].getMonth() + 1) + '-' + timeList[m].getDate();
                tempObj = {"createDate": time, "galleries": tempItems};
                vm.photoArray.push(tempObj);
            }
            getPagePhotoSelected();
        }

        function getPagePhotoSelected() {
            for (var i = 0; i < vm.photoArray.length; i++) {
                for (var j = 0; j < vm.photoArray[i].galleries.length; j++) {
                    vm.photoArray[i].galleries[j].selected = false;
                    for (var k = 0; k < vm.photoSelectAll.length; k++) {
                        if (vm.photoArray[i].galleries[j].id == vm.photoSelectAll[k].id) {
                            vm.photoArray[i].galleries[j].selected = true;
                        }
                    }
                }
            }
            photoArrayAllSelect();
        }

        // 判断是否重复
        function checkExist(list, obj) {
            if (list == null || list.length == 0) {
                return true;
            }
            for (var i = 0; i < list.length; i++) {
                if (list[i].getFullYear() == obj.getFullYear() &&
                    list[i].getMonth() == obj.getMonth() &&
                    list[i].getDate() == obj.getDate()) {
                    return false;
                }
            }
            return true;
        }

        //全选当前图片
        $scope.photoAllCb = function (item) {
            item.selected = !item.selected ? true : false;
            for (var i = 0; i < item.galleries.length; i++) {
                item.galleries[i].selected = item.selected;
            }
            if (item.selected) {
                photoSelect()
            } else {
                item.galleries.forEach(function (gallerie) {
                    deletePhotoSelect(gallerie)
                })
            }
        }
        //选中当前图片
        $scope.photoOneCb = function (item) {
            item.selected = !item.selected ? true : false;
            if (item.selected) {
                photoSelect()
            } else {
                deletePhotoSelect(item);
            }
            photoArrayAllSelect();
        }

        function photoSelect() {
            // vm.photoSelectAll = [];
            // var photoSelectAll = angular.copy(vm.photoSelectAll);
            for (var i = 0; i < vm.photoArray.length; i++) {
                for (var j = 0; j < vm.photoArray[i].galleries.length; j++) {
                    if (vm.photoArray[i].galleries[j].selected) {
                        var isPhoto = false;
                        for (var k = 0; k < vm.photoSelectAll.length; k++) {
                            if (vm.photoSelectAll[k].id == vm.photoArray[i].galleries[j].id) {
                                isPhoto = true;
                                break
                            }
                        }
                        if (!isPhoto) {
                            vm.photoSelectAll.push(vm.photoArray[i].galleries[j])
                        }
                    }
                }
            }
        }

        function deletePhotoSelect(item) {
            var index = null;
            for (var i = 0; i < vm.photoSelectAll.length; i++) {
                if (vm.photoSelectAll[i].id == item.id) {
                    index = i;
                    break
                }
            }
            vm.photoSelectAll.splice(index, 1);
        }

        function photoArrayAllSelect() {
            for (var i = 0; i < vm.photoArray.length; i++) {
                vm.photoArray[i].selected = true;
                for (var j = 0; j < vm.photoArray[i].galleries.length; j++) {
                    if (!vm.photoArray[i].galleries[j].selected) {
                        vm.photoArray[i].selected = false;
                    }
                }
            }
        }

        $scope.photoIndex = function (item) {
            var index = null;
            for (var i = 0; i < vm.photoSelectAll.length; i++) {
                if (vm.photoSelectAll[i].id == item.id) {
                    index = i;
                    break
                }
            }
            return index + 1;
        }
        //添加图片
        $scope.photoSave = function () {
            vm.page1 = true;
            for (var i = 0; i < vm.photoSelectAll.length; i++) {
                vm.photoSelectAll[i].seq = i + 1;
            }
            abook();
        }
        vm.windowDpi = js_getDPI();


        //主页
        vm.finalArray = [];
        vm.totalPrice = 0;
        vm.currentNavtab = 0;
        vm.typeImageData = {};
        vm.productXmlLines = [];

        function init() {
            if (!vm.isEdit) {
                Article.getByUser({id: $stateParams.aid}, function (article) {
                    if (article.status == 200) {
                        vm.article = article.data;
                        document.title = vm.article.productName;
                        if(vm.article.invalid){//作品失效
                            $state.go('articlesFailure',{storeId:vm.storeId});
                            return
                        }
                        Product.get({id: vm.pid, cancelled: false}, function (res) {
                            var product = res.data;
                            vm.typeImageData = res.data;
                            vm.pictureType = res.data.pictureType;
                            if(!vm.pictureType){vm.pictureType = 'single'}
                            ItemValue.get({id: $stateParams.vid}, function (res1) {
                                vm.productXmlLines = angular.copy(res1.data.productXmlLines);
                                vm.productXmlLine = angular.copy(res1.data.productXmlLines[0]);
                                angular.forEach(res1.data.productXmlLines, function (line) {
                                    if (line.id == vm.xmlId) {
                                        vm.productXmlLine = angular.copy(line);
                                    }
                                })

                                Gallery.all({
                                    items: [{key: "articleId", op: "=", value: vm.aid}],
                                    sort: sorts()
                                }, function (res){
                                    vm.photoSelectAll = [];
                                    if(res.data){
                                        res.data.forEach(function (item){
                                            item.galleries.forEach(function (gallerie){
                                                if (!gallerie.cancelled) {
                                                    gallerie.identifier = $scope.domain + gallerie.identifier;
                                                    vm.photoSelectAll.push(gallerie)
                                                }
                                            })
                                        })
                                    }
                                    getXmlToDucoment(vm.productXmlLine);
                                }, function (){

                                });
                            })
                            // var defaultItem = null;
                            // for (var i = 0; i < product.itemValues.length; i++) {
                            //     var tempItem = product.itemValues[i];
                            //     if (!tempItem.cancelled && tempItem.productXmlLines != null && tempItem.productXmlLines.length > 0) {
                            //         if (tempItem.default) {
                            //             defaultItem = tempItem;
                            //             vm.currentNavtab = i;
                            //         }
                            //         var name = '';
                            //         for (var j = 0; j < tempItem.objectValues.length; j++) {
                            //             var object = tempItem.objectValues[j];
                            //             name += object.propertyValueValue;
                            //         }
                            //         vm.finalArray.push({name: name, item: tempItem});
                            //     }
                            // }
                            // if (vm.finalArray.length > 0) {
                            //     if (!defaultItem) {
                            //         defaultItem = vm.finalArray[0].item;
                            //     }
                            //     vm.productXmlLines = [];
                            //     defaultItem.productXmlLines.forEach(function (productXmlLine) {
                            //         if (!productXmlLine.cancelled) {
                            //             vm.productXmlLines.push(productXmlLine)
                            //         }
                            //     })
                            //     for (var i = 0; i < defaultItem.productXmlLines.length; i++) {
                            //         var xmlline = defaultItem.productXmlLines[i];
                            //         if (xmlline.cancelled) {
                            //             continue;
                            //         }
                            //         getXmlToDucoment(xmlline);
                            //         vm.productXmlLine = xmlline;
                            //         break;
                            //     }
                            // } else {
                            //     swal({
                            //         title: $translate.instant("font.warmPrompt"),
                            //         text: $translate.instant("editorCommon.getTemplateError"),
                            //         imageUrl: Theme.sweetHintImg(),
                            //         timer: 3000,
                            //         showConfirmButton: false
                            //     });
                            //     vm.placeOrder = false;
                            // }
                        })
                    }
                },function(error){
                    if(error.status === 403){//token失效
                        return $state.go('404',{storeId:vm.storeId});
                    }
                    if(error.data.status === 404){//未找到作品
                        $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                    }
                })
            } else {
                Article.getByUser({id: $stateParams.aid}, function (res) {
                    vm.article = res.data;
                    document.title = vm.article.productName;
                    if(vm.article.invalid){//作品失效
                        $state.go('articlesFailure',{storeId:vm.storeId});
                        return
                    }
                    ItemValue.get({id: res.data.itemValueId}, function (resp) {
                        vm.article.skuNumber = resp.data.skuNumber;
                        vm.article.productBokeh = resp.data.productBokeh;
                        vm.article.productBokehType = resp.data.productBokehType;
                        vm.article.bokehtype = 'None';
                        vm.productXmlLines = [];
                        resp.data.productXmlLines.forEach(function (productXmlLine) {
                            if (!productXmlLine.cancelled) {
                                vm.productXmlLines.push(productXmlLine)
                            }
                        })
                        angular.forEach(vm.productXmlLines, function (line) {
                            if (line.productXmlId == vm.article.productXmlId) {
                                vm.productXmlLine = angular.copy(line);
                            }
                        });
                        getProduct(resp.data.productId, function () {
                            Convert.xmlToDocument2({
                                identifier: res.data.fileName,
                                provider: "qiniu",
                                editorType:vm.article.editorType,
                                pictureType:vm.pictureType,
                                // patternType:vm.product.patternType,
                            }, function (resp1) {
                                vm.counts = 0;
                                angular.forEach(resp1.pages.page, function (page, i) {
                                    if(!page.backup){
                                        angular.forEach(page.levels.level, function (level) {
                                            if (level.imagebox && level.imagebox.geometry) {
                                                vm.counts++;
                                            }
                                        })
                                    }
                                });
                                if (!resp1.pages.page || resp1.pages.page.length == 0) {
                                    swal({
                                        title: $translate.instant("font.warmPrompt"),
                                        text: $translate.instant("editorCommon.getTemplateError"),
                                        imageUrl: Theme.sweetHintImg(),
                                        timer: 3000,
                                        showConfirmButton: false
                                    });
                                    vm.placeOrder = false;
                                    return
                                }
                                $scope.template = angular.copy(resp1);
                                documentAnalysis($scope, $http, vm, $scope.template, FontManagement, 'processing', function (page) {
                                    ratioInfo(page)
                                }, function () {
                                    $timeout(function () {
                                        vm.loading = false;
                                        vm.windowH = window.innerHeight;
                                        vm.windowW = window.innerWidth;
                                        vm.placeOrder = true;
                                        vm.signalPage1 = getPage('P0',$scope.template.pages.page);
                                        vm.signalPage2 = getPage('P1',$scope.template.pages.page);
                                        vm.signalPage1.backup = false;
                                        vm.signalPage2.backup = false;
                                        var documentPage = $scope.template.pages.page;
                                        vm.data = [];
                                        if (vm.pictureType == 'single') {
                                            for (var i = 0; i < documentPage.length; i++) {
                                                if(!documentPage[i].backup){
                                                    var isRepetition = false;
                                                    for (var j = 0; j < vm.data.length; j++) {
                                                        if (documentPage[i].uuid === vm.data[j].uuid) {
                                                            isRepetition = true;
                                                            vm.data[j].quantity++
                                                            break;
                                                        }
                                                    }
                                                    if (!isRepetition) {
                                                        documentPage[i].quantity = 1;
                                                        vm.data.push(documentPage[i])
                                                    }
                                                }
                                            }
                                            ;
                                        }
                                        if (vm.pictureType == 'spread') {
                                            var _data = [];
                                            for (var i = 0; i < documentPage.length; i++) {
                                                var _documentPage2 = documentPage[i];
                                                if(!_documentPage2.backup){
                                                    var isRepetition1 = false;
                                                    if (i % 2 === 0) {
                                                        //偶数
                                                        for (var j = 0; j < vm.data.length; j++) {
                                                            if (_documentPage2.uuid === vm.data[j][0].uuid) {
                                                                vm.data[j][0].quantity++;
                                                                isRepetition1 = true;
                                                                break;
                                                            }
                                                        }
                                                        if (!isRepetition1) {
                                                            _documentPage2.quantity = 1;
                                                            _data.push(_documentPage2);
                                                        }
                                                    } else if (i % 2 === 1) {
                                                        //奇数
                                                        if (_data.length > 0) {
                                                            _data.push(_documentPage2);
                                                            vm.data.push(_data);
                                                            _data = [];
                                                        }
                                                    }
                                                }
                                            }
                                            ;
                                        }
                                        if (vm.data.length > 0) {
                                            getCostMethod();
                                        }
                                        reserveInit($scope.template.pages.page)
                                        $scope.$apply();
                                    }, 1000)
                                })
                            });
                        })
                    });
                },function (error) {
                    if(error.status === 403){//token失效
                        return $state.go('404',{storeId:vm.storeId});
                    }
                    if(error.data.status === 404){//未找到作品
                        $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                    }
                })
            }
        }

        function reserveInit(page) {
            var _page = angular.copy(page);
            vm.reservePage = [];
            _page.forEach(function (item) {
                if(item.backup){
                    vm.reservePage.push(item);
                }
            });
        }
        function getProduct(productId, callback) {
            Product.get({id: productId}, function (res) {
                vm.product = res.data;
                vm.typeImageData = res.data;
                vm.pictureType = res.data.pictureType;
                if(!vm.pictureType){vm.pictureType = 'single'}
                if (callback) {
                    callback()
                }
            })
        }

        vm.templateView = false;
        $scope.productXmlLineClick = function (item) {
            if(item.productXmlId == vm.article.productXmlId){
                vm.threeIsModal1 = true;
                vm.threeModalText1 = $translate.instant("editorCommon.alreadyExistTemplate");
                return
            }
            vm.temporaryTemplate = item;
            vm.isTemplate = true;
        };
        $scope.threeModalHide = function(){
            vm.threeIsModal1 = false;
        }
        $scope.templateReplace = function (data) {
            var singleData = angular.copy(vm.data);
            vm.loading = true;
            vm.isTemplate = false;
            vm.article.cover = vm.temporaryTemplate.coverImg;
            vm.article.themeClassifyId = vm.temporaryTemplate.productXmlThemeId;
            vm.article.themeClassifyName = vm.temporaryTemplate.productXmlThemeName;
            vm.article.productXmlId = vm.temporaryTemplate.productXmlId;
            vm.article.productXmlName = vm.temporaryTemplate.productXmlName;
            vm.article.musicIdentify = vm.temporaryTemplate.musicIdentify;
            vm.article.itemValueId = vm.temporaryTemplate.itemValueId;
            getXmlToDucoment(vm.temporaryTemplate,function () {
                vm.counts = 0;
                angular.forEach($scope.template.pages.page, function (page, i) {
                    if(!page.backup){
                        angular.forEach(page.levels.level, function (level) {
                            if (level.imagebox && level.imagebox.geometry) {
                                vm.counts++;
                            }
                        })
                    }
                });
                $scope.photos = [];
                vm.data = [];
                var _photo = [];
                if (vm.pictureType == 'single') {
                    singleData.forEach(function (data) {
                        var _photo1 = [];
                        data.levels.level.forEach(function (level) {
                            if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                var photo = {
                                    identifier:level.imagebox.image.resource.identifier,
                                    width:level.imagebox.image.imgWidth,
                                    height:level.imagebox.image.imgHeight,
                                    imgSize:level.imagebox.image.size,
                                    imageInfo:level.imagebox.image.imageInfo,
                                    quantity:data.quantity,
                                    uuid:data.uuid,
                                    resourceid:level.imagebox.image.resourceid,
                                    id:level.imagebox.image.resourceid,
                                    sourceId:level.imagebox.image.resourceid
                                }
                                _photo1.push(photo)
                            }
                        })
                        _photo.push(_photo1)
                    })
                }
                if (vm.pictureType == 'spread') {
                    singleData.forEach(function (singleData1) {
                        singleData1.forEach(function (data) {
                            var _photo1 = [];
                            data.levels.level.forEach(function (level) {
                                if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                    var photo = {
                                        identifier:level.imagebox.image.resource.identifier,
                                        width:level.imagebox.image.imgWidth,
                                        height:level.imagebox.image.imgHeight,
                                        imgSize:level.imagebox.image.size,
                                        imageInfo:level.imagebox.image.imageInfo,
                                        quantity:data.quantity,
                                        uuid:data.uuid,
                                        resourceid:level.imagebox.image.resourceid,
                                        id:level.imagebox.image.resourceid,
                                        sourceId:level.imagebox.image.resourceid
                                    }
                                    _photo1.push(photo)
                                }
                            })
                            _photo.push(_photo1)
                        })
                    })
                }
                abook1(_photo,function () {
                    if (vm.pictureType == 'spread') {
                        vm.data.forEach(function (item, index) {
                            if(singleData[index]){
                                item[0].quantity = singleData[index][0].quantity;
                                item[1].quantity = singleData[index][1].quantity;
                            }else{
                                item[0].quantity = 1;
                                item[1].quantity = 1;
                            }
                        })
                    }
                    if (vm.pictureType == 'single') {
                        vm.data.forEach(function (item, index) {
                            item.quantity = singleData[index] ? singleData[index].quantity : 1;
                        })
                    }
                    $timeout(function () {
                        vm.loading = false;
                        vm.isTemplate = false;
                        vm.templateView = false;
                        vm.page1 = true;
                        vm.page3 = false;
                        vm.levelIndex = null;
                        getCostMethod()
                    }, 1000)
                })
            });
        }
        $scope.getBack = function (item) {
            vm.levelIndex = null;
            if (item == 'page2') {
                vm.page1 = true;
                vm.page2 = false;
            }
            if (item == 'page3') {
                vm.page1 = true;
                vm.page3 = false;
                scroll('page'+vm.singleIndex)
            }
            $scope.photoGalleryHide();
        };

        //获取模版信息
        function getXmlToDucoment(xml,callback) {
            vm.article.cover = xml.coverImg;
            vm.article.themeClassifyId = xml.productXmlThemeId;
            vm.article.themeClassifyName = xml.productXmlThemeName;
            vm.article.productXmlId = xml.productXmlId;
            vm.article.productXmlName = xml.productXmlName;
            vm.article.musicIdentify = xml.musicIdentify;
            vm.article.itemValueId = xml.itemValueId;
            Convert.xmlToDocument2({
                identifier: xml.productXmlIdentifier,
                provider: "qiniu",
                editorType:vm.article.editorType,
                pictureType:vm.pictureType,
            }, function (res) {
                vm.counts = 0;
                angular.forEach(res.pages.page, function (page, i) {
                    if(!page.backup){
                        angular.forEach(page.levels.level, function (level) {
                            if (level.imagebox && level.imagebox.geometry) {
                                vm.counts++;
                            }
                        })
                    }
                });
                if (!res.status) {
                    if (!res.pages.page || res.pages.page.length == 0) {
                        swal({
                            title: $translate.instant("font.warmPrompt"),
                            text: $translate.instant("editorCommon.getTemplateError"),
                            imageUrl: Theme.sweetHintImg(),
                            timer: 3000,
                            showConfirmButton: false
                        });
                        vm.placeOrder = false;
                        return
                    }
                    $scope.template = angular.copy(res);
                    documentAnalysis($scope, $http, vm, $scope.template, FontManagement, 'processing', function (page) {
                        ratioInfo(page)
                    }, function () {
                        $timeout(function () {
                            vm.loading = false;
                            vm.placeOrder = true;
                            vm.signalPage1 = getPage('P0',$scope.template.pages.page);
                            vm.signalPage2 = getPage('P1',$scope.template.pages.page);
                            vm.signalPage1.backup = false;
                            vm.signalPage2.backup = false;
                            if(callback){
                                callback();
                                return
                            }
                            reserveInit($scope.template.pages.page)
                            abook();
                        }, 1000)
                    })
                } else {
                    swal({
                        title: $translate.instant("font.warmPrompt"),
                        text: res.message,
                        imageUrl: Theme.sweetHintImg(),
                        timer: 3000,
                        showConfirmButton: false
                    });
                }
            });
        }

        vm.ratioInfo = ratioInfo;

        function ratioInfo(item) {

            var windowRatio = 0.4;
            var windowRatio1 = 0.9;
            item.custom.ratio = $scope.dWidth * windowRatio / item.custom.width;
            // item.custom.ratio1 = $scope.dWidth * windowRatio1 / item.custom.width;


            vm.windowWidth = $(window).width() * 0.9;
            vm.windowHeight = $(window).height() - 300;
            var ratio = item.custom.width / item.custom.height;
            var ratio1 = vm.windowWidth / vm.windowHeight;
            if (ratio >= ratio1) {
                item.custom.ratio1 = vm.windowWidth / item.custom.width;
            } else {
                item.custom.ratio1 = vm.windowHeight / item.custom.height;
            }

            item.custom.thumbnailScale = 300 / item.custom.width;
        }

        $scope.finalArrayTab = function (index) {
            if (index != vm.currentNavtab) {
                vm.loading = true;
                var tempItem = vm.finalArray[index].item;
                if (tempItem.productXmlLines != null && tempItem.productXmlLines.length > 0) {
                    vm.productXmlLines = [];
                    tempItem.productXmlLines.forEach(function (productXmlLine) {
                        console.log(productXmlLine);
                        if (!productXmlLine.cancelled) {
                            vm.productXmlLines.push(productXmlLine)
                        }
                    })
                    for (var i = 0; i < tempItem.productXmlLines.length; i++) {
                        var xmlline = tempItem.productXmlLines[i];
                        if (xmlline.cancelled) {
                            continue;
                        }
                        getXmlToDucoment(xmlline,getXmlToDucomentCallback);
                        vm.productXmlLine = tempItem;

                        break;
                    }
                } else {
                    getXmlToDucoment(tempItem);
                    vm.productXmlLine = tempItem;
                }
                vm.currentNavtab = index;
            }
        }

        function getXmlToDucomentCallback(){
            var singleData = angular.copy(vm.data);
            vm.counts = 0;
            angular.forEach($scope.template.pages.page, function (page, i) {
                if(!page.backup){
                    angular.forEach(page.levels.level, function (level) {
                        if (level.imagebox && level.imagebox.geometry) {
                            vm.counts++;
                        }
                    })
                }
            });
            $scope.photos = [];
            vm.data = [];
            var _photo = [];
            if (vm.pictureType == 'single') {
                singleData.forEach(function (data) {
                    var _photo1 = [];
                    data.levels.level.forEach(function (level) {
                        if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                            var photo = {
                                identifier:level.imagebox.image.resource.identifier,
                                width:level.imagebox.image.imgWidth,
                                height:level.imagebox.image.imgHeight,
                                imgSize:level.imagebox.image.size,
                                imageInfo:level.imagebox.image.imageInfo,
                                quantity:data.quantity,
                                uuid:data.uuid,
                                resourceid:level.imagebox.image.resourceid,
                                id:level.imagebox.image.resourceid,
                                sourceId:level.imagebox.image.resourceid
                            }
                            _photo1.push(photo)
                        }
                    })
                    _photo.push(_photo1)
                })
            }
            if (vm.pictureType == 'spread') {
                singleData.forEach(function (singleData1) {
                    singleData1.forEach(function (data) {
                        var _photo1 = [];
                        data.levels.level.forEach(function (level) {
                            if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                var photo = {
                                    identifier:level.imagebox.image.resource.identifier,
                                    width:level.imagebox.image.imgWidth,
                                    height:level.imagebox.image.imgHeight,
                                    imgSize:level.imagebox.image.size,
                                    imageInfo:level.imagebox.image.imageInfo,
                                    quantity:data.quantity,
                                    uuid:data.uuid,
                                    resourceid:level.imagebox.image.resourceid,
                                    id:level.imagebox.image.resourceid,
                                    sourceId:level.imagebox.image.resourceid
                                }
                                _photo1.push(photo)
                            }
                        })
                        _photo.push(_photo1)
                    })
                })
            }
            abook1(_photo,function () {
                if (vm.pictureType == 'spread') {
                    vm.data.forEach(function (item, index) {
                        if(singleData[index]){
                            item[0].quantity = singleData[index][0].quantity;
                            item[1].quantity = singleData[index][1].quantity;
                        }else{
                            item[0].quantity = 1;
                            item[1].quantity = 1;
                        }
                    })
                }
                if (vm.pictureType == 'single') {
                    vm.data.forEach(function (item, index) {
                        item.quantity = singleData[index] ? singleData[index].quantity : 1;
                    })
                }
                $timeout(function () {
                    vm.loading = false;
                    vm.isTemplate = false;
                    vm.templateView = false;
                    vm.page1 = true;
                    vm.page3 = false;
                    vm.levelIndex = null;
                    console.log(vm.data);
                }, 1000)
            })
        }

        vm.data = [];

        function abook() {
            var _data = [];
            var _isSignalPage = true;
            var _isImageboxNull = false;
            for (var i = 0; i < vm.photoSelectAll.length; i++) {
                if(vm.photoSelectAll[i] && vm.photoSelectAll[i].identifier){
                    _isImageboxNull = false;
                    if(vm.pictureType == 'single'){
                        var _page = angular.copy(vm.signalPage1);
                        for (var i2 = 0; i2 < vm.data.length; i2++) {
                            var _page1 = null;
                            if (vm.data[i2].levels.level) {
                                for (var j = 0; j < vm.data[i2].levels.level.length; j++) {
                                    var _level = vm.data[i2].levels.level[j];
                                    if (_level.imagebox && _level.imagebox.image) {
                                        if (!_level.imagebox.image.resource || !_level.imagebox.image.resource.identifier) {
                                            _page1 = vm.data[i2];
                                            break
                                        }
                                    }
                                }
                            }
                            if(_page1){
                                _isImageboxNull = true;
                                _page = _page1;
                                break
                            }
                        }
                    }else{
                        var _page = null;
                        for (var i3 = 0; i3 < vm.data.length; i3++) {
                            var _page1 = null;
                            for(var i1 = 0; i1<vm.data[i3].length; i1++){
                                var page2 = vm.data[i3][i1];
                                if (page2.levels.level) {
                                    for (var j = 0; j < page2.levels.level.length; j++) {
                                        var _level = page2.levels.level[j];
                                        if (_level.imagebox && _level.imagebox.image) {
                                            if (!_level.imagebox.image.resource || !_level.imagebox.image.resource.identifier) {
                                                _page1 = page2;
                                                break
                                            }
                                        }
                                    }
                                }
                                if(_page1){break}
                            }
                            if(_page1){
                                _isImageboxNull = true;
                                _page = _page1;
                                break
                            }
                        }
                        if(!_isImageboxNull){
                            if(_isSignalPage){
                                _page = angular.copy(vm.signalPage1);
                            }else{
                                _page = angular.copy(vm.signalPage2);
                            }
                        }
                    }
                    var _isImagebox = false;
                    _page.levels.level.forEach(function (level) {
                        if (level.imagebox) {
                            _isImagebox = true;
                            level.imagebox.bokehtype = 'None';
                            level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                            level.imagebox.image = {
                                angle: 0,
                                filter: null,
                                filterResource: null,
                                offsetX: 0,
                                offsetY: 0,
                                resource: {identifier: vm.photoSelectAll[i].identifier, provider: "qiniu"},
                                size: vm.photoSelectAll[i].imgSize,
                                provider: "qiniu",
                                resourceid: vm.photoSelectAll[i].sourceId,
                                scale: 1,
                                width: vm.photoSelectAll[i].width,
                                height: vm.photoSelectAll[i].height,
                                imageInfo: vm.photoSelectAll[i].imageInfo,
                            };
                            level.imagebox.blurredrectangle = {
                                width: '',
                                height: '',
                                x: '',
                                y: '',
                            };
                            if(vm.pictureType == 'single'){
                                getBlurredrectangle(level.imagebox, 0);
                            }else{
                                getBlurredrectangle(level.imagebox, i);
                            }
                        }
                    })
                    if(!_isImageboxNull){
                        if (vm.pictureType == 'single') {
                            _page.quantity = 1;
                            _page.uuid = uuid();
                            vm.data.push(_page)
                        }
                        if (vm.pictureType == 'spread') {
                            _isSignalPage = !_isSignalPage;
                            if(!_isImagebox){
                                i--
                            }
                            _page.quantity = 1;
                            _page.uuid = uuid();
                            if (_data.length == 1) {
                                _page.uuid = _data[0].uuid;
                                _data.push(_page);
                                vm.data.push(_data)
                            } else {
                                _page.uuid = uuid();
                                _data = [];
                                _data.push(_page);
                                if(i == vm.photoSelectAll.length-1){
                                    var _page1 = angular.copy(vm.signalPage2);
                                    _page1.levels.level.forEach(function (level) {
                                        if(level.imagebox){
                                            _isImagebox = true;
                                            level.imagebox.bokehtype = 'None';
                                            level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                                            level.imagebox.image = {
                                                angle: 0,
                                                filter: null,
                                                filterResource: null,
                                                offsetx: 0,
                                                offsety: 0,
                                                resource: {identifier: "", provider: "qiniu"},
                                                size: "",
                                                provider: "qiniu",
                                                resourceid: "",
                                                scale: 1,
                                                width:"",
                                                height:"",
                                                imageInfo:{},
                                            };
                                            level.imagebox.blurredrectangle = {
                                                width:'',
                                                height:'',
                                                x:'',
                                                y:'',
                                            };
                                        }
                                    })
                                    _page1.uuid =_data[0].uuid;
                                    _data.push(_page1);
                                    vm.data.push(_data)
                                }
                            }
                        }
                    }
                }
            }
            vm.photoSelectAll = [];
            if (vm.data.length > 0) {
                getCostMethod();
            }
        }
        function abook1(gallaryPic,callback) {
            var _data = [];
            var _isSignalPage = true;
            for(var i=0; i<gallaryPic.length; i++){
                var index = i+1;
                if(vm.pictureType == 'single'){
                    var _page = angular.copy(vm.signalPage1);
                }else{
                    if(_isSignalPage){
                        var _page = angular.copy(vm.signalPage1);
                    }else{
                        var _page = angular.copy(vm.signalPage2);
                    }
                }
                var _isImagebox = false;
                _page.levels.level.forEach(function (level,i1) {
                    if(level.imagebox){
                        var _gallaryPic = null;
                        if(gallaryPic[i]){
                            _gallaryPic = gallaryPic[i][i1] ? gallaryPic[i][i1] : gallaryPic[i][0]
                        }
                        _isImagebox = true;
                        level.imagebox.bokehtype = 'None';
                        level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                        level.imagebox.image = {
                            angle: 0,
                            filter: null,
                            filterResource: null,
                            offsetx: 0,
                            offsety: 0,
                            resource: {identifier: !_gallaryPic ? "" : _gallaryPic.identifier, provider: "qiniu"},
                            size: !_gallaryPic ? "" : _gallaryPic.imgSize,
                            provider: "qiniu",
                            resourceid: !_gallaryPic ? "" : _gallaryPic.sourceId,
                            scale: 1,
                            width:!_gallaryPic ? "" : _gallaryPic.width,
                            height:!_gallaryPic ? "" : _gallaryPic.height,
                            imageInfo:!_gallaryPic ? {} : _gallaryPic.imageInfo,
                        };
                        level.imagebox.blurredrectangle = {
                            width:'',
                            height:'',
                            x:'',
                            y:'',
                        };
                        if(vm.pictureType == 'single'){
                            getBlurredrectangle(level.imagebox, 0);
                        }else{
                            getBlurredrectangle(level.imagebox, i);
                        }
                    }
                })

                if(vm.pictureType == 'single'){
                    _page.quantity=1;
                    _page.uuid = uuid();
                    vm.data.push(_page)
                }
                if(vm.pictureType == 'spread'){
                    _isSignalPage = !_isSignalPage;
                    if(!_isImagebox){
                        i--
                    }
                    _page.quantity=1;
                    _page.uuid = uuid();
                    if (_data.length == 1){
                        //偶数
                        _page.uuid =_data[0].uuid;
                        _data.push(_page);
                        vm.data.push(_data)

                    } else {
                        //奇数
                        _page.uuid = uuid();
                        _data = [];
                        _data.push(_page);
                        if(i == gallaryPic.length-1){
                            var _page1 = angular.copy(vm.signalPage2);
                            _page1.levels.level.forEach(function (level, i1) {
                                if(level.imagebox){
                                    _isImagebox = true;
                                    level.imagebox.bokehtype = 'None';
                                    level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                                    level.imagebox.image = {
                                        angle: 0,
                                        filter: null,
                                        filterResource: null,
                                        offsetx: 0,
                                        offsety: 0,
                                        resource: {identifier: "", provider: "qiniu"},
                                        size: "",
                                        provider: "qiniu",
                                        resourceid: "",
                                        scale: 1,
                                        width:"",
                                        height:"",
                                        imageInfo:{},
                                    };
                                    level.imagebox.blurredrectangle = {
                                        width:'',
                                        height:'',
                                        x:'',
                                        y:'',
                                    };
                                }
                            })
                            _page1.uuid =_data[0].uuid;
                            _data.push(_page1);
                            vm.data.push(_data)
                        }
                    }
                }
            }
            if (vm.data.length > 0) {
                getCostMethod();
            }
            if(callback){callback()}
        }

        function uuid() {
            var s = [];
            var hexDigits = "0123456789abcdef";
            for (var i = 0; i < 36; i++) {
                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
            }
            s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
            s[8] = s[13] = s[18] = s[23] = "-";

            var uuid = s.join("");
            return uuid;
        };

        function getBlurredrectangle(imagebox, index) {
            var item = imagebox;
            if (!item.image) {
                return
            }
            addImagebox($http, imagebox, function () {
                var newImg = angular.copy({
                    width: imagebox.image.width,
                    height: imagebox.image.height
                })
                if ((imagebox.geometry.width - imagebox.geometry.height) * (newImg.width - newImg.height) < 0) {
                    if (index % 2 === 0) {
                        if (imagebox.flip) {
                            imagebox.image.angle = imagebox.image.angle + 90;
                        } else {
                            imagebox.image.angle = imagebox.image.angle - 90;
                        }
                        imagebox.image.width = newImg.height;
                        imagebox.image.height = newImg.width;
                    }
                    if (index % 2 === 1) {
                        if (imagebox.flip) {
                            imagebox.image.angle = imagebox.image.angle - 90;
                        } else {
                            imagebox.image.angle = imagebox.image.angle + 90;
                        }
                        imagebox.image.width = newImg.height;
                        imagebox.image.height = newImg.width;
                    }
                } else {
                    imagebox.image.angle = imagebox.image.angle;
                }


                getBackgroundSource3(imagebox, imagebox.geometry.width, imagebox.geometry.height, imagebox.image, imagebox.bokehtype, $scope);
            });
            photoSelectAllInit();
        }

        function getBlurredrectangle1(data, callback) {
            var item = data.levels.level[0].imagebox;
            var newImg = new Image();
            newImg.crossOrigin = "Anonymous";
            newImg.src = item.image.resource.identifier;
            newImg.onload = function () {
                item.image.width = newImg.width;
                item.image.height = newImg.height;
                callback()
            };
        }

        $scope.single = function (index, pageIndex) {
            if(vm.isCopyBack){return}
            vm.singleData = [];
            if(vm.article.editorType == 'PICTURE'){
                vm.levelIndex = 0;
            }
            if (vm.pictureType == 'single') {
                vm.singleData = angular.copy(vm.data);
                vm.singleIndex = index;
            }
            if (vm.pictureType == 'spread') {
                vm.data.forEach(function (data) {
                    data.forEach(function (page) {
                        vm.singleData.push(angular.copy(page))
                    })
                })
                vm.singleIndex = index * 2 + pageIndex;
            }
            vm.page1 = false;
            vm.page3 = true;
        }
        $scope.singleIndexClick = function (item) {
            if(vm.article.editorType == 'PICTURE'|| vm.article.editorType == 'PICTURE_PRO'){
                vm.levelIndex = 0;
            }
            if ($scope.zfInit() != item) {
                if (item) {
                    vm.singleIndex++
                } else {
                    vm.singleIndex--
                }
            }
            $scope.photoGalleryHide();
        }
        $scope.previousPage = function () {
            if (vm.pictureType == 'spread') {
                if (!$scope.zfInit()) {
                    vm.singleIndex -= 2;
                } else {
                    vm.singleIndex--
                }
            } else {
                vm.singleIndex--;
            }
            if (vm.singleIndex < 0) {
                vm.singleIndex = 0;
            }
            vm.levelIndex = 0;
            $scope.photoGalleryHide();
        }
        $scope.nextPage = function () {
            if (vm.pictureType == 'spread') {
                if (!$scope.zfInit()) {
                    vm.singleIndex += 2;
                } else {
                    vm.singleIndex++;
                }
            } else {
                vm.singleIndex++;
            }

            if (vm.singleIndex > vm.singleData.length - 1) {
                vm.singleIndex = vm.singleData.length - 1;
            }
            vm.levelIndex = 0;
            $scope.photoGalleryHide();
        }

        $scope.zfInit = function () {
            if (vm.singleIndex % 2 === 0) {
                return false
            } else if (vm.singleIndex % 2 === 1) {
                return true
            }
        }
        $scope.singleInit = function (index) {
            if (vm.pictureType == 'spread') {
                return Math.ceil(index / 2)
            }
            return index
        }
        $scope.subtractDeleteTab = function ($event, index) {
            vm.data.splice(index, 1);
            getCostMethod()
            fn($event)
        }
        $scope.subtractQuantityTab = function (data) {
            if (data.quantity > 1) {
                data.quantity--;
                getCostMethod()
            }
        }
        $scope.pushQuantityTab = function (data) {
            data.quantity++;
            getCostMethod()
        }
        $scope.quantityChange = function (data) {
            getCostMethod()
        }

        //获取价格
        function getCostMethod() {
            vm.totalCount = 0;
            for (var k = 0; k < vm.data.length; k++) {
                if (vm.pictureType == 'spread') {
                    vm.totalCount += vm.data[k][0].quantity;
                } else {
                    vm.totalCount += vm.data[k].quantity;
                }
            }
            vm.totalCount *= 2;
        }

        $scope.fillImagebox = function () {
            vm.singleData[vm.singleIndex].levels.level.forEach(function (level, i) {
                if (level.imagebox && i == vm.levelIndex) {
                    var item = level.imagebox;
                    item.bokehtype = 'Transparent';
                    item.image.scale = 1;
                    if(item.blurredrectangle){
                        item.blurredrectangle.x = 0;
                        item.blurredrectangle.y = 0;
                    }
                    vm.imageboxLoad = true;
                    getBackgroundSource3(item, item.geometry.width, item.geometry.height, item.image, item.bokehtype, $scope, function () {
                        vm.imageboxLoad = false;
                    });
                }
            })
        }
        $scope.cutImagebox = function () {
            vm.singleData[vm.singleIndex].levels.level.forEach(function (level, i) {
                if (level.imagebox && i == vm.levelIndex) {
                    var item = level.imagebox;
                    item.bokehtype = 'None';
                    item.image.scale = 1;
                    if(item.blurredrectangle){
                        item.blurredrectangle.x = 0;
                        item.blurredrectangle.y = 0;
                    }
                    vm.imageboxLoad = true;
                    getBackgroundSource3(item, item.geometry.width, item.geometry.height, item.image, item.bokehtype, $scope, function () {
                        vm.imageboxLoad = false;
                    });
                }
            })
        }
        $scope.rotateImagebox = function () {
            if (vm.singleData[vm.singleIndex].levels.level[vm.levelIndex] && vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].imagebox) {
                var imagebox = vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].imagebox;
                imageboxRotate($scope, imagebox);
            }
        }

        /*******************************************照片框处理*************************************************************************/
        var isImagebox = false;
        var stickerDragBox = {};
        window.moveImageboxStart = function (e) {
            fn(e);
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            var textIndex = targ.dataset.index;
            textIndex = parseInt(textIndex);
            vm.levelIndex = textIndex;
            var sticker = vm.singleData[vm.singleIndex].levels.level[textIndex].imagebox;
            $scope.$apply();
            if (sticker.bokehtype != 'None') {
                return
            }

            stickerDragBox.x = e.changedTouches[0].clientX != null ? e.changedTouches[0].clientX : 0;          //鼠标到页面左边的距离
            stickerDragBox.y = e.changedTouches[0].clientY != null ? e.changedTouches[0].clientY : 0;          //鼠标到页面顶部的距离
            stickerDragBox.l = sticker.image.offsetx * vm.ratio1;
            stickerDragBox.t = sticker.image.offsety * vm.ratio1;
            stickerDragBox.r = -(sticker.image.width - vm.singleData[vm.singleIndex].levels.level[textIndex].imagebox.geometry.width) * vm.ratio1;
            stickerDragBox.b = -(sticker.image.height - vm.singleData[vm.singleIndex].levels.level[textIndex].imagebox.geometry.height) * vm.ratio1;
            isImagebox = true;
            $scope.$digest();
        }
        window.moveImageboxOn = function (e) {
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            var textIndex = targ.dataset.index;
            textIndex = parseInt(textIndex);
            var sticker = vm.singleData[vm.singleIndex].levels.level[textIndex].imagebox;
            if (vm.singleData[vm.singleIndex].levels.level[textIndex].imagebox.bokehtype != 'None') {
                return
            }
            fn(e);
            var angle = Number(sticker.rotation.angle);
            var cx = (stickerDragBox.l + stickerDragBox.r) / 2;
            var cy = (stickerDragBox.t + stickerDragBox.b) / 2;
            var pt0 = rotatePoint({x: stickerDragBox.x, y: stickerDragBox.y}, {x: cx, y: cy}, -angle);
            var pt1 = rotatePoint({x: e.touches[0].pageX, y: e.touches[0].pageY}, {x: cx, y: cy}, -angle);
            var deltaX = pt1.x - pt0.x;
            var deltaY = pt1.y - pt0.y;
            var nl = stickerDragBox.l + deltaX;
            var nt = stickerDragBox.t + deltaY;
            if (nl < stickerDragBox.r) {
                nl = stickerDragBox.r
            }
            ;
            if (nl > 0) {
                nl = 0
            }
            ;
            if (nt < stickerDragBox.b) {
                nt = stickerDragBox.b
            }
            ;
            if (nt > 0) {
                nt = 0
            }
            ;

            sticker.image.offsetx = nl / vm.ratio1;
            sticker.image.offsety = nt / vm.ratio1;
            $scope.$digest();
        }
        window.moveImageboxEnd = function (e) {
            fn(e);
            isImagebox = false;
            stickerDragBox = {}
        }
        $scope.singleSave = function () {
            vm.levelIndex = null;
            vm.data = [];
            if (vm.pictureType == 'single') {
                vm.data = vm.singleData;
            }
            if (vm.pictureType == 'spread') {
                var _data = [];
                vm.singleData.forEach(function (data, index) {
                    if (index % 2 === 0) {
                        //偶数
                        _data.push(data);
                    } else if (index % 2 === 1) {
                        //奇数
                        _data.push(data);
                        vm.data.push(_data);
                        _data = [];
                    }
                })
            }
            vm.page1 = true;
            vm.page3 = false;
            $scope.photoGalleryHide();
            scroll('page'+vm.singleIndex)
        };

        function scroll(id){
            $timeout(function () {
                var _top = $("#"+id).offset().top-60;
                $("html, body").animate({
                    scrollTop: _top + "px"
                }, {
                    duration: 0,
                    easing: "swing"
                });
            })
            return false;
        }
        $scope.isSave = false;
        function articleOverdue(callback){
            Article.get({id: vm.article.id}, function (article) {
                if(article.data.fileName != vm.article.fileName){
                    MessageService.minConfirm({
                        cancel:$translate.instant("button.cancel"),
                        confirm:$translate.instant("button.refresh"),
                        msg:$translate.instant("hint.articleOverdue")
                    },function(){
                        $state.go("editorPhotoEdit", {storeId: vm.storeId, aid:  vm.article.id},{reload:true});
                    })
                }else{
                    callback();
                }
            })
        }

        $scope.saveTab = function () {
            if(!vm.data ||vm.data.length==0){
                MessageService.hint('请添加图片');
                return
            }
            articleOverdue(function () {
                var _data = [];
                var _data1 = angular.copy(vm.data);
                var pageCount = 0;
                if (vm.pictureType == 'single') {
                    angular.forEach(_data1, function (item) {
                        for (var i = 0; i < item.quantity; i++) {
                            var itemData = angular.copy(item);
                            _data.push(itemData)
                        }
                    });
                    _data.forEach(function (data) {
                        data.quantity = 1;
                    })
                    pageCount = _data.length;
                }
                if (vm.pictureType == 'spread') {
                    _data1.forEach(function (data) {
                        for (var i = 0; i < data[0].quantity; i++) {
                            _data.push(data[0])
                            _data.push(data[1])
                        }
                    })
                    _data.forEach(function (data) {
                        data.quantity = 1;
                    })
                    pageCount = _data.length/2;
                }
                vm.article.articleStatus = "EDITING";
                if (vm.article.multiple) {//先编辑
                    if(vm.article.expectPageCount && vm.article.expectPageCount >= 0){
                        if(pageCount % vm.article.expectPageCount === 0){
                            vm.article.articleStatus = "COMPLETED";
                        }else{
                            MessageService.hint('当前冲印张数为' + pageCount+ ',总数量需要为'+vm.article.expectPageCount+'的倍数才可下单');
                            return
                        }
                    }else{
                        vm.article.articleStatus = "COMPLETED";
                    }
                }else{//先下单
                    if(vm.article.expectPageCount && vm.article.expectPageCount >= 0){
                        if(pageCount > vm.article.expectPageCount){
                            MessageService.hint('照片数量不可超过'+vm.article.expectPageCount+'份，请进行删减后保存');
                            return
                        }
                        if(pageCount == vm.article.expectPageCount){
                            vm.article.articleStatus = "COMPLETED";
                        }
                    }else{
                        vm.article.articleStatus = "COMPLETED";
                    }
                }
                for (var i = 0; i < _data.length; i++) {
                    var _page = _data[i];
                    if (_page.levels.level) {
                        for (var j = 0; j < _page.levels.level.length; j++) {
                            var _level = _page.levels.level[j];
                            if (_level.imagebox && _level.imagebox.image) {
                                if (!_level.imagebox.image.resource || !_level.imagebox.image.resource.identifier) {
                                    vm.threeIsModal1 = true;
                                    vm.threeModalText1 = '有照片框未填充照片，无法保存';
                                    return false
                                }
                            }
                        }
                    }
                }
                if (!vm.placeOrder && !$scope.isSave) {
                    return
                }
                // 生成缩略图保存作品
                vm.threeLoading = true;
                thumbnailInit1(function () {
                    if (_data && _data.length > 0) {
                        var _page = [];
                        angular.forEach(_data, function (_data1) {
                            var item = angular.copy(_data1);
                            _page.push(item)
                        })
                        $scope.template.pages.page = _page;
                        $scope.template = documentMM($scope.template, null, null);
                        $scope.isSave = true;
                        $scope.template.hostinfo = hostInfo();
                        Convert.articleUploadDocument($scope.template, function (res) {
                            vm.article.id = vm.aid;
                            vm.article.productType = 'CustomPrint';
                            vm.article.fileName = res.data.identifier;
                            vm.article.unFilledCount = 0;
                            vm.article.version = '2.0';
                            vm.article.pageCount = 0;
                            _page.forEach(function (itemPage) {
                                if(!itemPage.backup){
                                    vm.article.pageCount++
                                }
                            })
                            Article.update(vm.article, function (resp) {
                                vm.threeLoading = false;
                                $scope.isSave = false;
                                swal({
                                    title: $translate.instant("editorCommon.modifySuccess"),
                                    text: $translate.instant("editorCommon.synchronizationOfWorks"),
                                    imageUrl: Theme.sweetSuccedImg(),
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                                $state.go("editorPhotoPreview", {storeId: vm.storeId, aid: resp.data.id});
                            }, function () {
                                vm.threeLoading = false;
                                $scope.isSave = false;
                                swal({
                                    title: $translate.instant("editorCommon.saveError"),
                                    text: $translate.instant("editorCommon.saveError1"),
                                    imageUrl: Theme.sweetHintImg(),
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                            })
                        }, function () {
                            $scope.isSave = false;
                            vm.threeLoading = false;
                            swal({
                                title: $translate.instant("editorCommon.saveError"),
                                text: $translate.instant("editorCommon.saveError1"),
                                imageUrl: Theme.sweetHintImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                        })
                    }else{
                        vm.threeLoading = false;
                    }
                })
            })
        }
        function threeInfo(item) {
            vm.threeError = true;
            vm.threeErrorText = item;
            $timeout(function () {
                vm.threeError = false;
                vm.threeErrorText = "";
            }, 1500)
        }

        //像素不足提醒
        vm.imgSize = 150;
        vm.imgDpi = 150;
        StoreWorkEditConfig.storeId({storeId: vm.storeId}, function (res) {
            if (res.status == 200) {
                vm.imgSize = res.data.imgSize;
                vm.imgDpi = res.data.imgDpi;
            }
        });
        var throttledResize = angular.throttle(function (item) {
            console.log('No browser drain at each event please.');
            return pxInsufficient(item, vm.imgSize, vm.imgDpi)
        }, 1000);

        $scope.pxInsufficient = function (item) {
            // return throttledResize(item);
            return pxInsufficient(item, vm.imgSize, vm.imgDpi);
        }
        //边框
        $scope.imageboxW = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.width
                    // return Number(imagebox.geometry.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100)
                }
            } else {
                return imagebox.geometry.width
            }
        }
        $scope.imageboxH = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.height
                    // return Number(imagebox.geometry.height) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100)
                }
            } else {
                return imagebox.geometry.height
            }
        }
        $scope.imageboxL = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.x
                    // return Number(imagebox.geometry.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
                }
            } else {
                return imagebox.geometry.x
            }
        }
        $scope.imageboxT = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.y
                    // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                }
            } else {
                return imagebox.geometry.y
            }
        }
        $scope.imageboxWEdge = function (imagebox, edge) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    if (edge == 'left') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100
                    }
                    if (edge == 'top') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100
                    }
                    if (edge == 'right') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100
                    }
                    if (edge == 'bottom') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100
                    }
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return _radius > 0 ?  (_radius + Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius1 = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return  (_radius - Number(imagebox.border.lineWidth)/2) > 0 ? (_radius - Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return (_r - Number(imagebox.border.lineWidth)/2) > 0 ? (_r - Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.dropShadow = function (imagebox, ratio, ratio1) {
            if (imagebox.shadow == null) {
                return
            }
            var width = $scope.imageboxW(imagebox) * ratio;
            var height = $scope.imageboxH(imagebox) * ratio1;
            return dropShadow(imagebox, width, height)
        }
        //图片框的操作
        $scope.imageboxSite = function (item) {
            if (!vm.singleData[vm.singleIndex].levels.level[vm.levelIndex] || vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].imagebox.bokehtype != 'None') {
                return
            }
            var _imagebox = vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].imagebox;
            if (item == 'center') {
                _imagebox.image.offsetx = -(_imagebox.image.width * _imagebox.image.scale - _imagebox.geometry.width) / 2 / _imagebox.image.scale;
                _imagebox.image.offsety = -(_imagebox.image.height * _imagebox.image.scale - _imagebox.geometry.height) / 2 / _imagebox.image.scale;
            }
            if (_imagebox.image.offsetx > 0) {
                _imagebox.image.offsetx = 0;
            }
            if (_imagebox.image.offsety > 0) {
                _imagebox.image.offsety = 0;
            }
        }

        //生成封面图
        function thumbnailInit1(callback) {
            $timeout(function () {
                html2canvas_adapt(document.getElementById('thumbnail-view1'), {
                    onrendered: function (canvas) {
                        if(canvas){
                            var url = canvas.toDataURL("image/png");
                            var blob = dataURLtoBlob(url);
                            var formData = new FormData();
                            formData.append('file', blob);
                            formData.append('key', '3dThumbnail-' + UidService.get());
                            formData.append('token', vm.token);
                            $.ajax({
                                cache: true,
                                type: "POST",
                                url: 'https://upload.qiniup.com/',
                                data: formData,
                                async: false,
                                contentType: false,
                                processData: false,
                                success: function (data) {
                                    var identifier = data.key;
                                    vm.article.covered = true;
                                    vm.article.cover = identifier;
                                    if (callback) {callback()};
                                },
                                error: function () {
                                    vm.article.covered=false;
                                    vm.article.cover=null;
                                    if (callback) {callback()};
                                }
                            })
                        }else{
                            vm.article.covered=false;
                            vm.article.cover=null;
                            if (callback) {callback()};
                        }
                    },
                    error:function(){
                        vm.article.covered=false;
                        vm.article.cover=null;
                        if (callback) {callback()};
                    },
                    letterRendering: true,
                    useCORS: true,
                    width: $scope.template.pages.page[0].custom.width * $scope.template.pages.page[0].custom.thumbnailScale,
                    height: $scope.template.pages.page[0].custom.height * $scope.template.pages.page[0].custom.thumbnailScale,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: document.body.scrollHeight,
                    x: 0,
                    y: 0,
                    backgroundColor: "rgba(0, 0, 0, 0)"
                });
            })
        }
        /*******************************************点击素材*************************************************************************/
        $scope.materialShow = function (){
            vm.isClassify = true;
        }
        vm.repeatAddNumber = 0;
        $scope.addMaterial = function (text){
            var item = JSON.parse(text);
            vm.singleData[vm.singleIndex].levels.level.push(item);
        }
        $scope.closeMaterial = function (text){
            vm.isClassify = false;
        }
        /*******************************************点击素材end*************************************************************************/
        /*******************************************文字*************************************************************************/
        //获取所有字体
        getAllFont();
        vm.fontList = [];
        function getAllFont() {
            vm.fontList = [];
            FontManagement.getAll({}, function (res) {
                vm.fontList = res;
            });
        }
        vm.textboxType = null;
        $scope.fontShow = function (){
            vm.textboxType = 'add';
        }
        $scope.textboxAdd = function (text){
            // var item = JSON.parse(text);
            $scope.photoGalleryHide();
        }
        $scope.textboxEdit = function (text){
            var item = JSON.parse(text);
            vm.singleData[vm.singleIndex].levels.level = item;
        }
        $scope.textboxDelete = function (){
            if (vm.singleData[vm.singleIndex].levels.level[vm.levelIndex] && vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].textbox) {
                vm.singleData[vm.singleIndex].levels.level.splice(vm.levelIndex, 1);
                $scope.photoGalleryHide()
            }
        }
        vm.sizeHeight1 = sizeHeight1;
        function sizeHeight1() {
            $timeout(function () {
                var _h = Number($("#c-xml-page-material-fontId1 span").height()) + Number(10);
                var _w = Number($("#c-xml-page-material-fontId1 span").width()) + Number(10);
                var _sizeWidth = getTextWidthInternal(vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].textbox.text, vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].textbox.style.bold, vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].textbox.style.italic, vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].textbox.style.size, vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].textbox.fontuuid);
                if(!writingmodeInit(vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].textbox.style.writingmode)){
                    vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].textbox.geometry.height = _h;
                    if(vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].textbox.geometry.width < vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].textbox.style.size){
                        vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].textbox.geometry.width = vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].textbox.style.size;
                    }
                    if(vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].textbox.geometry.width < _sizeWidth){
                        item.geometry.width = (Number(_sizeWidth) + Number(5));
                        return;
                    }
                }
                if(writingmodeInit(vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].textbox.style.writingmode)){
                    vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].textbox.geometry.width = _w;
                    if(vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].textbox.geometry.height < _sizeWidth){
                        vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].textbox.geometry.height = _sizeWidth;
                    }
                }
            })
        }
        /*******************************************文字end*************************************************************************/
        /*******************************************点击图片库*************************************************************************/
        vm.mapDepot = false;
        vm.isRecommend = false;
        $scope.photoGalleryShow = function (isRecommend) {
            vm.isRecommend = isRecommend;
            vm.mapDepot = true;
            vm.mapDepot2 = true;
            vm.mapDepot1 = false;
            if(!isRecommend){
                vm.photoAll = [];
                vm.indexNum = 0;
                vm.photoCompleted = false;
                loadAll('isNull');
            }else{
                ThemeClassify.getAllByCondition4({
                    type: 'RECOMMENDPIC',
                    storeId: vm.storeId
                }, function (res) {
                    vm.recommendClassify = res.data;
                    vm.recommendClassifyItem = res.data[0];
                });
            }
        }

        vm.levelDome = function(item){
            vm.isClassify = false;
        }
        $scope.photoGalleryHide = function () {
            vm.mapDepot = false;
            vm.isClassify = false;
            vm.textboxSelect = false;
            vm.isLayout = false;
            vm.textboxType = null;
            if(vm.article.editorType == 'PICTURE_PRO'){
                vm.levelIndex = null;
            }
        }
        $('#photoGallery').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(!vm.photoCompleted){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.indexNum++
                    loadAll("isNull")
                }
            }
        })
        var dragGal = {};
        var drag = false;
        var startChangeTimeout;
        vm.isSmallPic = false;
        window.startChange = function (e) {
            startChangeTimeout = setTimeout(function() {
                vm.isSmallPic = true;
                $scope.$digest();
            }, 200);
            drag = true;
            var i = e.target.dataset.imgindex;
            if(i >=0){
                if(vm.mapDepot1){
                    $scope.galleryArr = vm.photoSelectAll1[i];
                    $scope.galleryChoose = vm.photoSelectAll1[i].identifier;
                }
                if(vm.isRecommend){
                    $scope.galleryArr = vm.recommendPics[i];
                    $scope.galleryChoose = vm.recommendPics[i].identifier;
                }else{
                    $scope.galleryArr = vm.photoAll[i];
                    $scope.galleryChoose = vm.photoAll[i].identifier;
                }

                $scope.galleryId = i;
                $scope.galleryX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX - 53 : 0;
                $scope.galleryY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY - 40 : 0;
                dragGal.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
                dragGal.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;
                dragGal.pageX = e.changedTouches[0].pageX ? e.changedTouches[0].pageX : 0;
                dragGal.pageY = e.changedTouches[0].pageY ? e.changedTouches[0].pageY : 0;
                dragGal.coordX = $scope.galleryX;
                dragGal.coordY = $scope.galleryY;
                $scope.$digest();
                return false;
            }
        };

        window.dragChange = function (e) {
            clearTimeout(startChangeTimeout);
            if (drag) {
                //手指离开屏幕
                $scope.galleryX = (dragGal.coordX + (e.changedTouches[0].clientX - dragGal.clientX));
                $scope.galleryY = (dragGal.coordY + (e.changedTouches[0].clientY - dragGal.clientY));

                // if (Math.abs($scope.galleryY - dragGal.coordY) > 100) {
                //     vm.isSmallPic = true;
                // }


                //向上滑动;
                if ($scope.galleryY <= 0) {
                    $scope.galleryY = 0;
                }
                //向左滑动;        <-----
                if ($scope.galleryX <= 0) {
                    $scope.galleryX = 0;
                }
                //向下滑动;
                if ($scope.galleryY >= $scope.dHeight - 62) {
                    $scope.galleryY = $scope.dHeight - 62;
                }
                // 向右滑动;    ---->
                if ($scope.galleryX >= $scope.dWidth - 62) {
                    $scope.galleryX = $scope.dWidth - 62;
                }

                var _picTop = $("#small-pic").offset().top;
                var _picLeft = $("#small-pic").offset().left;
                var _exist = false;
                for (var i1 = vm.singleData[vm.singleIndex].levels.level.length-1; i1 >= 0; i1--) {
                    var _level = vm.singleData[vm.singleIndex].levels.level[i1];
                    if (_level.imagebox) {
                        var _imageboxLeft = $(".imagebox" + i1).offset().left;
                        var _imageboxTop = $(".imagebox" + i1).offset().top;
                        var _imageboxRight = _imageboxLeft + $(".imagebox" + i1).width();
                        var _imageboxBottom = _imageboxTop + $(".imagebox" + i1).height();
                        if ((_imageboxTop < _picTop) && (_picTop < _imageboxBottom) && (_imageboxLeft < _picLeft) && (_picLeft < _imageboxRight) && !_exist) {
                            _exist = true;
                            $(".imagebox" + i1).find(".imageboxHint").css("background", "rgba(103, 106, 108, 0.8)")
                            break;
                        }else{
                            $(".imagebox" + i1).find(".imageboxHint").css("background", "rgba(0,0,0,0.2)")
                        }
                    }
                }
                $scope.$digest();
                return false;
            }
        };
        window.stopChange = function (e) {
            clearTimeout(startChangeTimeout);
            if (!drag) {
                return
            }
            drag = false;
            var _picTop = $("#small-pic").offset().top;
            var _picLeft = $("#small-pic").offset().left;
            for (var i1 = vm.singleData[vm.singleIndex].levels.level.length-1; i1 >= 0; i1--) {
                var _level = vm.singleData[vm.singleIndex].levels.level[i1];
                if (_level.imagebox) {
                    var _imageboxLeft = $(".imagebox" + i1).offset().left;
                    var _imageboxTop = $(".imagebox" + i1).offset().top;
                    var _imageboxRight = _imageboxLeft + $(".imagebox" + i1).width();
                    var _imageboxBottom = _imageboxTop + $(".imagebox" + i1).height();
                    if ((_imageboxTop < _picTop) && (_picTop < _imageboxBottom) && (_imageboxLeft < _picLeft) && (_picLeft < _imageboxRight)) {
                        $scope.replaceImagebox1($scope.galleryArr, i1)
                        break;
                    }
                }
            }
            vm.isSmallPic = false;
            $scope.$digest();
        };

        $scope.replaceImagebox1 = function (data, index) {
            vm.levelIndex = index;
            replaceImagebox(data)
        }

        function replaceImagebox(data, callback) {
            var item = angular.copy(data);
            var _imagebox = vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].imagebox;
            _imagebox.image = {
                resource: {
                    identifier: item.identifier,
                    provider: 'qiniu'
                },
                resourceid:item.sourceId,
                filterResource: {
                    identifier: '',
                    provider: 'qiniu'
                },
                imageInfo: item.imageInfo,
                width: item.width,
                height: item.height,
                imgSize: item.imgSize,
                index: item.seq,
                parameter: angular.fromJson(item.parameter),
                scale: 1,
                angle: 0
            }
            _imagebox.blurredrectangle = {
                width: '',
                height: '',
                x: '',
                y: '',
            };
            if(vm.pictureType == 'spread'){
                getBlurredrectangle(_imagebox, $scope.zfInit() ? 1 : 0);
            }else{
                getBlurredrectangle(_imagebox, 0);
            }
        }
        function photoSelectAllInit() {
            vm.photoSelectAll1 = [];
            if(vm.singleData){
                vm.singleData.forEach(function (page) {
                    page.levels.level.forEach(function (level) {
                        if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                            var photo = {
                                identifier:level.imagebox.image.resource.identifier,
                                width:level.imagebox.image.imgWidth,
                                height:level.imagebox.image.imgHeight,
                                imgSize:level.imagebox.image.size,
                                imageInfo:level.imagebox.image.imageInfo,
                                resourceid:level.imagebox.image.resourceid,
                                id:level.imagebox.image.resourceid
                            }
                            var _is = false;
                            for(var i=0; i<vm.photoSelectAll1.length; i++){
                                if(vm.photoSelectAll1[i].id == level.imagebox.image.resourceid){
                                    _is = true;
                                    break
                                }
                            }
                            if(!_is){
                                vm.photoSelectAll1.push(photo)
                            }
                        }
                    })
                })
            }
        }

        $scope.addImageboxImg = function (item) {
            if(vm.singleData && vm.singleData[vm.singleIndex] && vm.singleData[vm.singleIndex].levels.level && vm.singleData[vm.singleIndex].levels.level[vm.levelIndex] && vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].imagebox){
                replaceImagebox(item)
            }
        };

        //复制背面
        vm.isCopyBack = false;
        vm.copyBackIndex == null;
        $scope.copyBack = function($event, index){
            fn($event)
            vm.isCopyBack = true;
            vm.copyBackIndex = index;
        }
        $scope.copyHide = function(){
            vm.isCopyBack = false;
            vm.copyBackIndex == null;
        }
        $scope.copyAll = function(){
            vm.data.forEach(function(data,dataIndex){
                if(dataIndex != vm.copyBackIndex){
                    data[1] = JSON.parse(JSON.stringify(vm.data[vm.copyBackIndex][1]));
                    data[1].uuid = uuid();
                }
            })
            MessageService.hint("复制成功")
            $scope.copyHide();
        }
        $scope.editorPageCopyHere = function($event, index){
            fn($event)
            vm.data[index][1] = JSON.parse(JSON.stringify(vm.data[vm.copyBackIndex][1]));
            vm.data[index][1].uuid = uuid();
            MessageService.hint("复制成功")
        }
        //布局库
        vm.isLayout = false;
        $scope.reserveShow = function(){
            vm.isLayout = true;
        }
        $scope.layoutCb = function(item){
            if(vm.singleData[vm.singleIndex].config.partid!=item.config.partid){
                MessageService.hint("不是相同部件的版式不可替换");
                return
            }
            if(item.uuid != vm.singleData[vm.singleIndex].uuid){
                vm.threeLoading = true;
                vm.threeLoadingText = $translate.instant("editorCommon.loadingText2");
                item.config = vm.singleData[vm.singleIndex].config;
                var pages = {
                    config:$scope.template.config,
                    pages:{
                        page: [angular.copy(item)]
                    }
                }
                documentAnalysis($scope, $http, vm, pages,FontManagement,'processing', function(page){
                    ratioInfo(page)
                }, function () {
                    $timeout(function () {
                        var layoutPage = pages.pages.page[0];
                        layoutPage.backup = false;
                        layoutPage.unreplace = false;
                        layoutPage.quantity = vm.singleData[vm.singleIndex].quantity;
                        pageDataInfo(layoutPage)
                    },200)
                })
            }
        }
        function pageDataInfo(page) {
            vm.threeLoading = true;
            vm.threeLoadingText = $translate.instant("editorCommon.loadingText2");
            page.uuid = getUuid();
            var _pageData = angular.copy(vm.singleData[vm.singleIndex]);
            var _pageDataImageboxImage = [];
            var _pageDataImageboxImageSum = 0;
            for(var i=0; i<_pageData.levels.level.length; i++){
                var _lecel = _pageData.levels.level[i];
                if(_lecel.imagebox && _lecel.imagebox.image && _lecel.imagebox.image.resource && _lecel.imagebox.image.resource.identifier){
                    _lecel.imagebox.imgRatio = _lecel.imagebox.image.width/_lecel.imagebox.image.height;
                    _pageDataImageboxImage.push(_lecel.imagebox)
                }
            }
            if(!page.levels.level){
                page.levels.level=[];
            }
            _pageDataImageboxImage = imageboxAdapt(page.levels.level, _pageDataImageboxImage);
            angular.forEach(page.levels.level, function (level, i) {
                if (level.imagebox) {
                    var _imagebox = null;
                    for(var j=0; j<_pageDataImageboxImage.length; j++){
                        if(_pageDataImageboxImage[j].imageboxIndex == _pageDataImageboxImageSum){
                            _imagebox = _pageDataImageboxImage[j];
                            break
                        }
                    }
                    _pageDataImageboxImageSum++;
                    if (_imagebox) {
                        level.imagebox.bokehtype = level.imagebox.bokehtype || vm.article.bokehtype || 'None';
                        level.imagebox.image = {
                            blurredrectangle: {
                                width: 0,
                                height: 0,
                                x: 0,
                                y: 0
                            },
                            resource: {
                                identifier: _imagebox ? _imagebox.image.resource.identifier : '',
                                provider: _imagebox ? _imagebox.image.resource.provider : 'qiniu'
                            },
                            resourceid:_imagebox ? _imagebox.image.resourceid : '',
                            filterResource: {
                                identifier: '',
                                provider: 'qiniu'
                            },
                            angle: 0,
                            offsetx: 0,
                            offsety: 0,
                            imageInfo: _imagebox? _imagebox.image.imageInfo : '',
                            width: _imagebox ? _imagebox.image.width : '',
                            height: _imagebox ? _imagebox.image.height : '',
                            imgSize: _imagebox ? _imagebox.image.imgSize : '',
                            index: _imagebox ? _imagebox.image.seq : '',
                            parameter: _imagebox ? angular.fromJson(_imagebox.image.parameter) : [],
                            scale: 1,
                            brightness:_imagebox ?_imagebox.image.brightness : 0,
                            contrast: _imagebox ?_imagebox.image.contrast : 0,
                            saturate: _imagebox ?_imagebox.image.saturate : 0,
                            temperature: _imagebox ?_imagebox.image.temperature : 0,
                            hue: _imagebox ?_imagebox.image.hue : 0
                        }
                        if (level.imagebox.image.resource.identifier) {
                            if(vm.pictureType == 'spread'){
                                getBlurredrectangle(level.imagebox, $scope.zfInit() ? 1 : 0);
                            }else{
                                getBlurredrectangle(level.imagebox, 0);
                            }
                        }
                    } else {
                        level.imagebox.bokehtype = level.imagebox.bokehtype || vm.article.bokehtype || 'None';
                        level.imagebox.image = {
                            resource: {
                                identifier: '',
                                provider: ''
                            },
                            resourceid:"",
                            filterResource: {
                                identifier: '',
                                provider: ''
                            },
                            width: '',
                            height: '',
                            imgSize: '',
                            offsetx: 0,
                            offsety: 0,
                            index: '',
                            parameter: [],
                            scale: 1,
                            angle: 0,
                        }
                    }
                }
            })
            $timeout(function () {
                vm.singleData[vm.singleIndex] = page;
                vm.singleData[vm.singleIndex].backup = false;
                vm.singleData[vm.singleIndex].unreplace = false;
                vm.threeLoading = false;
                vm.threeLoadingText = '';
                MessageService.hint($translate.instant("editorCommon.changePageSuccess"));
            },200)
        }
    }
})();
