(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('EditorPhotoBothWebController', EditorPhotoBothWebController);
    EditorPhotoBothWebController.$inject = ['$scope', 'FontManagement', 'UidService', 'MessageService', '$stateParams', '$q',
    'ThemeClassify', 'ProductXml', 'Store', 'PlatformWechatSetting','Qinius','Folder','$uibModal', 'Gallery', '$localStorage', 'Material', 'ClipArt', 'FontXmlsManger','Backgrounds','Product',
    'ItemValue', 'Convert', '$http', '$timeout', 'FontXmlsFileServes', 'Article', 'entity', '$state', 'SweetAlert', 'Theme',
    'MessageNotification', 'DateUtils','ListService','ImageService', 'CommonService','OrderArticle', 'StoreWorkEditConfig',
     '$translate', '$cookies', 'translationStorageProvider', 'IMAGE_SUPPORTED_MIME','StoreStorages','$websocket'];

    function EditorPhotoBothWebController( $scope, FontManagement, UidService, MessageService, $stateParams, $q,
        ThemeClassify, ProductXml, Store, PlatformWechatSetting, Qinius, Folder, $uibModal, Gallery, $localStorage, Material, ClipArt, FontXmlsManger,Backgrounds,Product,
      ItemValue, Convert, $http, $timeout, FontXmlsFileServes, Article, entity, $state, SweetAlert, Theme,
      MessageNotification, DateUtils,ListService,ImageService, CommonService, OrderArticle, StoreWorkEditConfig,
      $translate, $cookies, translationStorageProvider, IMAGE_SUPPORTED_MIME,StoreStorages, $websocket) {
        var vm = this;
        vm.page = [0,1];
        vm.editorDialog = false;
        $(".navbar").hide();
        $(".save-page").removeClass('hide1');
        $(".wrap").hide();
        if($stateParams.authorization){
            $localStorage.authenticationToken = $stateParams.authorization;
        };
        vm.uploadFailedList = [];//上传图片失败的列表
        vm.errorAlertPopup = false;//显示左下角蓝色弹窗
        vm.templateReplace = null;
        vm.isEdit = entity.type == 'edit' ? true : false;
        vm.storeId = $stateParams.storeId;
        vm.pid = $stateParams.pid;
        vm.aid = $stateParams.aid;
        vm.articleId = null;//当orderArticle 时有值；
        vm.vid = $stateParams.vid;
        vm.xmlId = $stateParams.xmlId;
        vm.supportedMimeTypes = IMAGE_SUPPORTED_MIME;
        if ($localStorage.user != null) {
            vm.userId = $localStorage.user.id
        }
        var arr = localStorage.getItem('save-article');
        if (arr && vm.pid) {
            $state.go("editorAllEdit", {storeId: vm.storeId, aid: arr});
        }
        localStorage.removeItem('save-article');
        $scope.domains = Qinius.getDomain();
        vm.tokenTime = 360000;
        Qinius.get({}, function (res) {
            vm.token = res.token;
            vm.tokenTime = parseInt((res.expireAt - new Date().getTime()));
        });
        vm.platformSetting = PlatformWechatSetting.get();
        vm.store = Store.get({id: vm.storeId});
        $scope.gallaryPic = JSON.parse(localStorage.getItem("photoSelectAll")) || [];
        vm.pageIndex = 0;
        vm.data = [];


        //测试时使用
        // $localStorage.lagnuages = ['zh-cn', 'en'];
        $scope.showLagnuages = function(){
            vm.showLagnuages = !vm.showLagnuages;
            if (vm.showLagnuages) {
                $(".lang-icn").css({'transform': 'rotate(270deg)'});
            }else{
                $(".lang-icn").css({'transform': 'rotate(90deg)'});
            }
        }

        lagnuageInit();
        function lagnuageInit() {
            vm.lang = $cookies.getObject("NG_TRANSLATE_LANG_KEY");
            vm.lagnuages =[];
            if ($localStorage.lagnuages) {
                for(var i = 0 ; i<$localStorage.lagnuages.length; i++ ){
                    var lang = $localStorage.lagnuages[i];
                    var result = translationStorageProvider.getLanguageType(lang);
                    if (!!result) {
                        if (vm.lang == result.value) {
                            vm.langKey = result.key;
                            result.selected = true;
                        }
                        vm.lagnuages.push(result);
                    }
                }
            }
        }

        function getProductXmlLine(callback){
            ItemValue.get({id: vm.article.itemValueId}, function (res) {
                angular.forEach(res.data.productXmlLines, function (line) {
                    if (line.productXmlId == vm.article.productXmlId) {
                        vm.productXmlLine = angular.copy(line);
                    }
                });
                if(callback){callback()}
            })
            Product.get({id: vm.article.productId, cancelled: false}, function (res) {
                vm.product = res.data;
                vm.pictureType = res.data.pictureType;
                vm.productTitle = res.data.name;
            })
        }
        $scope.changeLagnuage = function(lang){
            $timeout(function () {
                if (lang == null || lang.key == vm.langKey ) {
                    return;
                }
                angular.forEach(vm.lagnuages, function(item){
                    item.selected =false;
                });
                lang.selected = true;
                vm.langKey = lang.key;
                $translate.use(lang.value);
                vm.showLagnuages =false;
                $timeout(function () {
                    lagnuageInit();
                    getProductXmlLine();
                },100)
            })
        }



        /***************页面处理************start*****************/
        $q.all([$scope.domains.$promise, vm.store.$promise, vm.platformSetting.$promise]).then(function () {
            // token过期
            vm.platformSetting = vm.platformSetting.data;
            setTimeout(function fn() {
                Qinius.get(function (msg) {
                    vm.token = msg.token;
                    vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                });
                setTimeout(fn, vm.tokenTime);
            }, vm.tokenTime);
            vm.store = vm.store.data;
            $(".navbar").hide();
            $(".save-page").removeClass('hide1');
            $(".wrap").hide();
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            vm.logo = vm.store.menuLogo ? $scope.domain + vm.store.menuLogo : 'content/images/momentsgo.png';
            js_getDPI()

            //消息列表
            vm.messageList = "";
            MessageNotification.getAllByCondition({
                items:[
                    {key:"pageType", op : "=", value:"Edit"},
                    {key:"storeId", op : "=", value:vm.storeId},
                    {key:"available", op : "=", value:"true"},
                    {key:"validate", op : ">=", value: DateUtils.convertLocalDateToServer(new Date())}]
                }, function (res) {
                    if(res.status == 200){
                        res.data.forEach(function (item, index) {
                            vm.messageList += (index+1) + ":" + item.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";
                        })
                    }
                }
            )
            //获取数据
            init()
        });
        vm.navSelect = function(item){
            vm.nav = item;
        }
        vm.templateView = false;
        $scope.productXmlLineClick = function(item){
            if (item.productXmlId === vm.article.productXmlId) {
                MessageService.error($translate.instant("editorMessage.canNotBeReplacedTemplate"));
                return
            }
            vm.temporaryTemplate = item;
            vm.isTemplate = true;
            vm.templateReplace= true;
        };
        $scope.templateReplace = function(data){
            var singleData = angular.copy(vm.data);
            vm.loading = true;
            vm.isTemplate = false;
            vm.templateReplace = null;
            vm.article.productXmlId = vm.temporaryTemplate.productXmlId;
            getXmlToDucoment(vm.temporaryTemplate,function () {
                // $scope.photos = [];
                vm.data = [];
                var _photo = [];
                if (vm.pictureType == 'single') {
                    singleData.forEach(function (data) {
                        var _photo1 = [];
                        data.levels.level.forEach(function (level) {
                            if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                var photo = {
                                    identifier:level.imagebox.image.resource.identifier,
                                    width:level.imagebox.image.imgWidth,
                                    height:level.imagebox.image.imgHeight,
                                    imgSize:level.imagebox.image.size,
                                    imageInfo:level.imagebox.image.imageInfo,
                                    quantity:data.quantity,
                                    uuid:data.uuid,
                                    resourceid:level.imagebox.image.resourceid,
                                    id:level.imagebox.image.resourceid,
                                    sourceId:level.imagebox.image.resourceid
                                }
                                _photo1.push(photo)
                            }
                        })
                        _photo.push(_photo1)
                    })
                }
                if (vm.pictureType == 'spread') {
                    singleData.forEach(function (singleData1) {
                        singleData1.forEach(function (data) {
                            var _photo1 = [];
                            data.levels.level.forEach(function (level) {
                                if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                    var photo = {
                                        identifier:level.imagebox.image.resource.identifier,
                                        width:level.imagebox.image.imgWidth,
                                        height:level.imagebox.image.imgHeight,
                                        imgSize:level.imagebox.image.size,
                                        imageInfo:level.imagebox.image.imageInfo,
                                        quantity:data.quantity,
                                        uuid:data.uuid,
                                        resourceid:level.imagebox.image.resourceid,
                                        id:level.imagebox.image.resourceid,
                                        sourceId:level.imagebox.image.resourceid
                                    }
                                    _photo1.push(photo)
                                }
                            })
                            _photo.push(_photo1)
                        })
                    })
                }
                abook1(_photo,function () {
                    if (vm.pictureType == 'spread') {
                        vm.data.forEach(function (item, index) {
                            if(singleData[index]){
                                item[0].quantity = singleData[index][0].quantity;
                                item[1].quantity = singleData[index][1].quantity;
                            }else{
                                item[0].quantity = 1;
                                item[1].quantity = 1;
                            }
                        })
                    }
                    if (vm.pictureType == 'single') {
                        vm.data.forEach(function (item, index) {
                            item.quantity = singleData[index] ? singleData[index].quantity : 1;
                        })
                    }
                    $timeout(function () {
                        vm.loading = false;
                        vm.page1 = true;
                        vm.page3 = false;
                        vm.levelIndex = null;
                        console.log(vm.ratio)
                    }, 1000)
                })
            });
        }

        /***********************  编辑区域  ****************************/
        vm.finalArray = [];
        vm.currentNavtab = 0;
        vm.productXmlLines = [];
        function init(){
            vm.loading = true;
            if(!vm.isEdit){
                Article.getByUser({id: $stateParams.aid}, function (article) {
                    if(article.status == 200) {
                        vm.article = article.data;
                        //获取照片文件夹
                        loadFolders();
                        websocket();
                        if(vm.article.invalid){//作品失效
                            $state.go('articlesFailure',{storeId:vm.storeId});
                            return
                        }
                        CommonService.setDocumentTitle(vm.article.name+'-'+$translate.instant("title"));
                        ItemValue.get({id: vm.vid}, function (res) {
                            vm.itemValue = res.data;
                            $scope.status = localStorage.getItem('Production');
                            vm.productXmlLines = res.data.productXmlLines;
                            vm.productTitle = res.data.productName;
                            angular.forEach(res.data.productXmlLines,function (line) {
                                if(line.id==vm.xmlId){
                                    vm.productXmlLine = angular.copy(line);
                                }
                            })
                            if(!vm.productXmlLine){
                                angular.forEach(res.data.productXmlLines,function (line) {
                                    if(!line.cancelled){
                                        vm.productXmlLine = angular.copy(line);
                                    }
                                })
                            }
                            vm.article.cover = vm.productXmlLine.coverImg;
                            vm.article.themeClassifyId = vm.productXmlLine.productXmlThemeId;
                            vm.article.themeClassifyName = vm.productXmlLine.productXmlThemeName;
                            vm.article.productXmlId = vm.productXmlLine.productXmlId;
                            vm.article.productXmlName = vm.productXmlLine.productXmlName;
                            vm.article.musicIdentify = vm.productXmlLine.musicIdentify;
                            vm.article.itemValueId = res.data.id || vm.vid;
                            getProduct(function () {
                                getXmlToDucoment(vm.productXmlLine);
                            })
                        });
                    }
                },function (error) {
                    if(error.status === 403){//token失效
                        return $state.go('404',{storeId:vm.storeId});
                    }
                    if(error.data.status === 404){//未找到作品
                        $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                    }
                })
            }else{
                if($stateParams.aid){
                    Article.getByUser({id: $stateParams.aid}, function (res) {
                        vm.article = res.data;
                        //获取照片文件夹
                        loadFolders();
                        websocket();
                        if(vm.article.invalid){//作品失效
                            $state.go('articlesFailure',{storeId:vm.storeId});
                            return
                        }
                        CommonService.setDocumentTitle(vm.article.name+'-'+$translate.instant("title"));
                        ItemValue.get({id: res.data.itemValueId}, function (resp) {
                            vm.itemValue = resp.data;
                            vm.article.skuNumber = resp.data.skuNumber;
                            vm.productTitle = resp.data.productName;
                            vm.article.productBokeh = resp.data.productBokeh;
                            vm.article.productBokehType = resp.data.productBokehType;
                            vm.article.bokehtype = 'None';
                            vm.productXmlLines = resp.data.productXmlLines;
                            angular.forEach(vm.productXmlLines, function (line) {
                                if (line.productXmlId == vm.article.productXmlId) {
                                    vm.productXmlLine = angular.copy(line);
                                }
                            });
                            getProduct(function () {
                                Convert.xmlToDocument2({
                                    identifier: res.data.fileName,
                                    provider: "qiniu",
                                    editorType:vm.article.editorType,
                                    pictureType:vm.pictureType,
                                    // patternType:vm.product.patternType,
                                }, function (resp1) {
                                    if(!resp1.pages.page || resp1.pages.page.length == 0){
                                        swal({
                                            title: $translate.instant("editorCommon.hint"),
                                            text: $translate.instant("editorMessage.templateFailure"),
                                            imageUrl: Theme.sweetHintImg(),
                                            timer: 3000,
                                            showConfirmButton: false
                                        });
                                        vm.placeOrder = false;
                                        return
                                    }
                                    editDocument(resp1)
                                }, function (error) {
                                    if(error.data.status === 403){//未授权
                                        $state.go('404',{storeId:vm.storeId});
                                    }
                                    MessageService.error(error.data.message || $translate.instant("editorMessage.templateFailure"));
                                });
                            })
                        });
                    },function (error) {
                        if(error.status === 403){//token失效
                            return $state.go('404',{storeId:vm.storeId});
                        }
                        if(error.data.status === 404){//未找到作品
                            $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                        }
                    })
                }else if($stateParams.orderArticleId){
                    OrderArticle.getByUser({id: $stateParams.orderArticleId}, function (res) {
                        vm.article = res.data;
                        websocket();
                        if(vm.article.invalid){//作品失效
                            $state.go('articlesFailure',{storeId:vm.storeId});
                            return
                        }
                        CommonService.setDocumentTitle(vm.article.name+'-'+$translate.instant("title"));
                        vm.articleId = res.data.articleId;
                        //获取照片文件夹
                        loadFolders();
                        ItemValue.get({id: res.data.itemValueId}, function (resp) {
                            vm.itemValue = resp.data;
                            vm.article.skuNumber = resp.data.skuNumber;
                            vm.productTitle = resp.data.productName;
                            vm.article.productBokeh = resp.data.productBokeh;
                            vm.article.productBokehType = resp.data.productBokehType;
                            vm.article.bokehtype = 'None';
                            vm.productXmlLines = resp.data.productXmlLines;
                            angular.forEach(vm.productXmlLines, function (line) {
                                if (line.productXmlId == vm.article.productXmlId) {
                                    vm.productXmlLine = angular.copy(line);
                                }
                            });
                            getProduct(function () {
                                Convert.xmlToDocument2({
                                    identifier: res.data.fileName,
                                    provider: "qiniu",
                                    editorType:vm.article.editorType,
                                    pictureType:vm.pictureType,
                                    // patternType:vm.product.patternType,
                                }, function (resp1) {
                                    if(!resp1.pages.page || resp1.pages.page.length == 0){
                                        swal({
                                            title: $translate.instant("editorCommon.hint"),
                                            text: $translate.instant("editorMessage.templateFailure"),
                                            imageUrl: Theme.sweetHintImg(),
                                            timer: 3000,
                                            showConfirmButton: false
                                        });
                                        vm.placeOrder = false;
                                        return
                                    }
                                    editDocument(resp1)
                                }, function (error) {
                                    if(error.data.status === 403){//未授权
                                        $state.go('404',{storeId:vm.storeId});
                                    }
                                    MessageService.error(error.data.message || $translate.instant("editorMessage.templateFailure"));
                                });
                            })
                        });
                    },function (error) {
                        if(error.status === 403){//token失效
                            return $state.go('404',{storeId:vm.storeId});
                        }
                        if(error.data.status === 404){//未找到作品
                            $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                        }
                    })
                }
            }
        }
        function getProduct(callback) {
            Product.get({id: vm.itemValue.productId}, function (res) {
                vm.product = res.data;
                vm.pictureType = res.data.pictureType;
                if(callback){callback()}
            })
        }

        function editDocument(resp1) {
            $scope.template = angular.copy(resp1);
            vm.photoSelectAll = $scope.gallaryPic;
            documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'processing', function(page){}, function () {
                $timeout(function () {
                    var _top = vm.messageList > 0 ? 152 :112;
                    // vm.windowH = window.innerHeight - _top - 30;
                    // vm.windowW = window.innerWidth - 460;
                    // vm.windowW1 = 140;
                    vm.windowH = 668;
                    vm.windowW = 1132;
                    vm.windowW1 = 232;
                    vm.placeOrder = true;
                    vm.signalPage1 = getPage('P0',$scope.template.pages.page);
                    vm.signalPage2 = getPage('P1',$scope.template.pages.page);
                    vm.signalPage1.backup = false;
                    vm.signalPage2.backup = false;
                    var windowRatio = 0.95;
                    var custom = $scope.template.pages.page[0].custom;
                    vm.pageWidth = custom.width;
                    vm.pageHeight = custom.height;
                    if(vm.windowH / vm.pageHeight >= vm.windowW / vm.pageWidth ){
                        vm.ratio = vm.windowW / vm.pageWidth * windowRatio;
                    }else {
                        vm.ratio = vm.windowH / vm.pageHeight * windowRatio;
                    }
                    vm.ratio1 = vm.windowW1 / vm.pageWidth;
                    var documentPage = $scope.template.pages.page;
                    vm.data = [];
                    if(vm.pictureType == 'single'){
                        for (var i = 0; i < documentPage.length; i++) {
                            if(!documentPage[i].backup){
                                var isRepetition = false;
                                for(var j=0; j<vm.data.length; j++){
                                    if (documentPage[i].uuid === vm.data[j].uuid) {
                                        isRepetition = true;
                                        vm.data[j].quantity++
                                        break;
                                    }
                                }
                                if(!isRepetition){
                                    documentPage[i].quantity = 1;
                                    vm.data.push(documentPage[i])
                                }
                            }
                        };
                    }
                    if(vm.pictureType == 'spread'){
                        var _data = [];
                        for (var i = 0; i < documentPage.length; i++) {
                            if(!documentPage[i].backup){
                                var _documentPage2 = documentPage[i];
                                var isRepetition1 = false;
                                if (i % 2 === 0){
                                    //偶数
                                    for(var j=0; j<vm.data.length; j++){
                                        if (_documentPage2.uuid === vm.data[j][0].uuid) {
                                            vm.data[j][0].quantity++;
                                            isRepetition1 = true;
                                            break;
                                        }
                                    }
                                    if(!isRepetition1){
                                        _documentPage2.quantity = 1;
                                        _data.push(_documentPage2);
                                    }
                                } else if (i % 2 === 1){
                                    //奇数
                                    if(_data.length>0){
                                        _data.push(_documentPage2);
                                        vm.data.push(_data);
                                        _data = [];
                                    }
                                }
                            }
                        };
                    }
                    vm.pageSum = vm.data.length - 1;
                    loadAll('isNull');
                    reserveInit($scope.template.pages.page);
                    vm.loading = false;
                    $scope.$apply();
                },1000)
            })
        }

        //获取模版信息
        function getXmlToDucoment(productXml,callback){
            Convert.xmlToDocument2({
                identifier: productXml.productXmlIdentifier,
                provider: "qiniu",
                editorType:vm.article.editorType,
                pictureType:vm.pictureType,
                // patternType:vm.product.patternType,
            }, function (res) {
                $scope.template = angular.copy(res);
                vm.photoSelectAll = $scope.gallaryPic;
                documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'processing', function(page){}, function () {
                    $timeout(function () {
                        // var _top = vm.messageList > 0 ? 152 :112;
                        // vm.windowH = window.innerHeight - _top - 30;
                        // vm.windowW = window.innerWidth - 460;
                        // vm.windowW1 = 140;
                        console.log(window.innerHeight);
                        vm.windowH = 668;
                        vm.windowW = 1132;
                        vm.windowW1 = 232;
                        vm.placeOrder = true;
                        $scope.template.pages.page.forEach(function (page) {
                            page.backup = true;
                        })
                        vm.signalPage1 = getPage('P0',$scope.template.pages.page);
                        vm.signalPage2 = getPage('P1',$scope.template.pages.page);
                        vm.signalPage1.backup = false;
                        vm.signalPage2.backup = false;
                        var windowRatio = 0.95;
                        var custom = $scope.template.pages.page[0].custom;
                        vm.pageWidth = custom.width;
                        vm.pageHeight = custom.height;
                        if(vm.windowH / vm.pageHeight >= vm.windowW / vm.pageWidth ){
                            vm.ratio = vm.windowW / vm.pageWidth * windowRatio;
                        }else {
                            vm.ratio = vm.windowH / vm.pageHeight * windowRatio;
                        }
                        vm.ratio1 = vm.windowW1 / vm.pageWidth;
                        vm.loading = false;
                        if (!callback) {
                            loadAll('isNull');
                        }
                        reserveInit($scope.template.pages.page);
                        if(callback){callback()}
                    }, 1000)
                })
            }, function (error) {
                if(error.data.status === 403){//未授权
                    $state.go('404',{storeId:vm.storeId});
                }
                MessageService.error(error.data.message || $translate.instant("editorMessage.templateFailure"));
            });
        }

        function abook(gallaryPic) {
            var _data = [];
            var _isSignalPage = true;
            for(var i=0; i<gallaryPic.length; i++){
                var index = i+1;
                if(vm.pictureType == 'single'){
                    var _page = angular.copy(vm.signalPage1);
                }else{
                    if(_isSignalPage){
                        var _page = angular.copy(vm.signalPage1);
                    }else{
                        var _page = angular.copy(vm.signalPage2);
                    }
                }
                var _isImagebox = false;
                _page.levels.level.forEach(function (level) {
                    if(level.imagebox){
                        _isImagebox = true;
                        level.imagebox.bokehtype = 'None';
                        level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                        level.imagebox.image = {
                            angle: 0,
                            filter: null,
                            filterResource: null,
                            offsetx: 0,
                            offsety: 0,
                            resource: {identifier: gallaryPic[i].identifier, provider: "qiniu"},
                            size: gallaryPic[i].imgSize,
                            provider: "qiniu",
                            resourceid: gallaryPic[i].sourceId,
                            scale: 1,
                            width:gallaryPic[i].width,
                            height:gallaryPic[i].height,
                            imageInfo:gallaryPic[i].imageInfo,
                        };
                        level.imagebox.blurredrectangle = {
                            width:'',
                            height:'',
                            x:'',
                            y:'',
                        };
                        if(vm.pictureType == 'single'){
                            getBlurredrectangle(level.imagebox, 0);
                        }else{
                            getBlurredrectangle(level.imagebox, i);
                        }
                    }
                })

                if(vm.pictureType == 'single'){
                    _page.quantity=1;
                    _page.uuid = uuid();
                    vm.data.push(_page)
                }
                if(vm.pictureType == 'spread'){
                    _isSignalPage = !_isSignalPage;
                    if(!_isImagebox){
                        i--
                    }
                    _page.quantity=1;
                    _page.uuid = uuid();
                    if (index % 2 === 0){
                        //偶数
                        _page.uuid =_data[0].uuid;
                        _data.push(_page);
                        vm.data.push(_data)

                    } else if (index % 2 === 1){
                        //奇数
                        _page.uuid = uuid();
                        _data = [];
                        _data.push(_page);
                        if(i == gallaryPic.length-1){
                            var _page1 = angular.copy(vm.signalPage2);
                            _page1.levels.level.forEach(function (level) {
                                if(level.imagebox){
                                    _isImagebox = true;
                                    level.imagebox.bokehtype = 'None';
                                    level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                                    level.imagebox.image = {
                                        angle: 0,
                                        filter: null,
                                        filterResource: null,
                                        offsetx: 0,
                                        offsety: 0,
                                        resource: {identifier: "", provider: "qiniu"},
                                        size: "",
                                        provider: "qiniu",
                                        resourceid: "",
                                        scale: 1,
                                        width:"",
                                        height:"",
                                        imageInfo:{},
                                    };
                                    level.imagebox.blurredrectangle = {
                                        width:'',
                                        height:'',
                                        x:'',
                                        y:'',
                                    };
                                }
                            })
                            _page1.uuid =_data[0].uuid;
                            _data.push(_page1);
                            vm.data.push(_data)
                        }
                    }
                }
            }
            if(vm.data.length>0){
                vm.pageSum = vm.data.length - 1;
            }
            gallaryPic = [];
            $scope.photos.forEach(function (photo) {
                photo.select = false;
            })
            photosNum();
            $scope.whetherChoose(false);
        }
        function abook1(gallaryPic,callback) {
            var _data = [];
            var _isSignalPage = true;
            for(var i=0; i<gallaryPic.length; i++){
                var index = i+1;
                if(vm.pictureType == 'single'){
                    var _page = angular.copy(vm.signalPage1);
                }else{
                    if(_isSignalPage){
                        var _page = angular.copy(vm.signalPage1);
                    }else{
                        var _page = angular.copy(vm.signalPage2);
                    }
                }
                var _isImagebox = false;
                _page.levels.level.forEach(function (level,i1) {
                    if(level.imagebox){
                        var _gallaryPic = null;
                        if(gallaryPic[i]){
                            _gallaryPic = gallaryPic[i][i1] ? gallaryPic[i][i1] : gallaryPic[i][0]
                        }
                        _isImagebox = true;
                        level.imagebox.bokehtype = 'None';
                        level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                        level.imagebox.image = {
                            angle: 0,
                            filter: null,
                            filterResource: null,
                            offsetx: 0,
                            offsety: 0,
                            resource: {identifier: !_gallaryPic ? "" : _gallaryPic.identifier, provider: "qiniu"},
                            size: !_gallaryPic ? "" : _gallaryPic.imgSize,
                            provider: "qiniu",
                            resourceid: !_gallaryPic ? "" : _gallaryPic.sourceId,
                            scale: 1,
                            width:!_gallaryPic ? "" : _gallaryPic.width,
                            height:!_gallaryPic ? "" : _gallaryPic.height,
                            imageInfo:!_gallaryPic ? {} : _gallaryPic.imageInfo,
                        };
                        level.imagebox.blurredrectangle = {
                            width:'',
                            height:'',
                            x:'',
                            y:'',
                        };
                        if(vm.pictureType == 'single'){
                            getBlurredrectangle(level.imagebox, 0);
                        }else{
                            getBlurredrectangle(level.imagebox, i);
                        }
                    }
                })

                if(vm.pictureType == 'single'){
                    _page.quantity=1;
                    _page.uuid = uuid();
                    vm.data.push(_page)
                }
                if(vm.pictureType == 'spread'){
                    _isSignalPage = !_isSignalPage;
                    if(!_isImagebox){
                        i--
                    }
                    _page.quantity=1;
                    _page.uuid = uuid();
                    if (index % 2 === 0){
                        //偶数
                        _page.uuid =_data[0].uuid;
                        _data.push(_page);
                        vm.data.push(_data)

                    } else if (index % 2 === 1){
                        //奇数
                        _page.uuid = uuid();
                        _data = [];
                        _data.push(_page);
                        if(i == gallaryPic.length-1){
                            var _page1 = angular.copy(vm.signalPage2);
                            _page1.levels.level.forEach(function (level, i1) {
                                if(level.imagebox){
                                    _isImagebox = true;
                                    level.imagebox.bokehtype = 'None';
                                    level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                                    level.imagebox.image = {
                                        angle: 0,
                                        filter: null,
                                        filterResource: null,
                                        offsetx: 0,
                                        offsety: 0,
                                        resource: {identifier: "", provider: "qiniu"},
                                        size: "",
                                        provider: "qiniu",
                                        resourceid: "",
                                        scale: 1,
                                        width:"",
                                        height:"",
                                        imageInfo:{},
                                    };
                                    level.imagebox.blurredrectangle = {
                                        width:'',
                                        height:'',
                                        x:'',
                                        y:'',
                                    };
                                }
                            })
                            _page1.uuid =_data[0].uuid;
                            _data.push(_page1);
                            vm.data.push(_data)
                        }
                    }
                }
            }
            if(vm.data.length>0){
                vm.pageSum = vm.data.length - 1;
            }
            gallaryPic = [];
            $scope.photos.forEach(function (photo) {
                photo.select = false;
            })
            photosNum();
            if(callback){callback()}
        }
        function reserveInit(page) {
            var _page = angular.copy(page);
            vm.reservePage = [];
            _page.forEach(function (item) {
                if(item.backup){
                    vm.reservePage.push(item);
                }
            });
        }
        function uuid() {
            var s = [];
            var hexDigits = "0123456789abcdef";
            for (var i = 0; i < 36; i++) {
                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
            }
            s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
            s[8] = s[13] = s[18] = s[23] = "-";

            var uuid = s.join("");
            return uuid;
        };
        function getBlurredrectangle(imagebox, index) {
            var item = imagebox;
            if(!item.image){
                return
            }
            addImagebox($http, imagebox, function () {
                var newImg = angular.copy({
                    width: imagebox.image.width,
                    height: imagebox.image.height
                })
                if ((imagebox.geometry.width - imagebox.geometry.height) * (newImg.width - newImg.height) < 0) {
                    if (index % 2 === 0){
                        if(imagebox.flip){
                            imagebox.image.angle = imagebox.image.angle+90;
                        }else{
                            imagebox.image.angle = imagebox.image.angle-90;
                        }
                        imagebox.image.width = newImg.height;
                        imagebox.image.height = newImg.width;
                    }
                    if (index % 2 === 1){
                        if(imagebox.flip){
                            imagebox.image.angle = imagebox.image.angle-90;
                        }else{
                            imagebox.image.angle = imagebox.image.angle+90;
                        }
                        imagebox.image.width = newImg.height;
                        imagebox.image.height = newImg.width;
                    }
                } else {
                    imagebox.image.angle = imagebox.image.angle;
                }
                getBackgroundSource3(imagebox, imagebox.geometry.width, imagebox.geometry.height, imagebox.image, imagebox.bokehtype, $scope);
            });
        }

        $scope.fillImagebox = function () {
            var item = vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.scale = 1;
            vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.blurredrectangle.x = 0;
            vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.blurredrectangle.y = 0;
            item.bokehtype = 'Transparent';
            vm.imageboxLoad = true;
            getBackgroundSource3(item, item.geometry.width, item.geometry.height, item.image, item.bokehtype, $scope, function () {
                vm.imageboxLoad = false;
                // $scope.$apply();
            });
        }
        $scope.cutImagebox = function () {
            var item = vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.scale = 1;
            vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.blurredrectangle.x = 0;
            vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.blurredrectangle.y = 0;
            item.bokehtype = 'None';
            vm.imageboxLoad = true;
            getBackgroundSource3(item, item.geometry.width, item.geometry.height, item.image, item.bokehtype, $scope, function () {
                vm.imageboxLoad = false;
                // $scope.$apply();
            });
        }
        $scope.rotateImagebox = function () {
            imageboxRotate($scope, vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox);
        }
        $scope.previousPage = function () {
            if(vm.pictureType == 'spread'){
                if(!$scope.zfInit()){
                    vm.pageIndex -= 2;
                }else{
                    vm.pageIndex--
                }
            }else{
                vm.pageIndex--;
            }
            if(vm.pageIndex<0){
                vm.pageIndex = 0;
            }
            vm.levelIndex = 0;
        }
        $scope.nextPage = function () {
            if(vm.pictureType == 'spread'){
                if(!$scope.zfInit()){
                    vm.pageIndex += 2;
                }else{
                    vm.pageIndex++;
                }
            }else{
                vm.pageIndex++;
            }

            if(vm.pageIndex>vm.editorPageList.length-1){
                vm.pageIndex = vm.editorPageList.length-1;
            }
            vm.levelIndex = 0;
        }
        $scope.turning = function (index) {
            vm.pageIndex = index;
            vm.levelIndex = null;
        }
        $scope.editorDialogHide = function () {
            vm.editorDialog=false;
            $timeout(function () {
                var element = document.getElementById('page'+vm.pageIndex);
                element.scrollIntoView();
            })
        }
        $scope.singleIndexClick = function (item) {
            vm.levelIndex = null;
            if($scope.zfInit() != item){
                if(item){
                    vm.pageIndex++
                }else{
                    vm.pageIndex--
                }
            }
        }
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpi = arrDPI[1];
            // vm.windowDpi = 300;
            return arrDPI;
        }

        /*******************************************  照片框处理  *************************************************************************/
        var isImagebox = false;
        vm.isImageboxTrim = false;
        var stickerDragBox = {};
        $scope.moveImageboxStart = function (index, $event) {
            vm.levelIndex = index;
            fn($event);
            var sticker = vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            if(sticker.bokehtype != 'None' || !sticker.image){
                return
            }
            var x = $event.clientX;
            var y = $event.clientY;
            var proW = vm.ratio*sticker.image.scale;
            var proH = vm.ratio*sticker.image.scale;

            var l = sticker.image.offsetx * proW * sticker.image.scale;
            var t = sticker.image.offsety * proH * sticker.image.scale;
            var r = -(sticker.image.width * sticker.image.scale - sticker.geometry.width) * proW;
            var b = - (sticker.image.height * sticker.image.scale - sticker.geometry.height) * proH;
            var angle = Number(sticker.rotation.angle);
            //开关打开
            isImagebox = true;
            vm.isImageboxTrim = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isImagebox == false) {
                    return;
                }
                fn(e);
                var cx = (l + r) / 2;
                var cy = (t + b) / 2;
                var pt0 = rotatePoint({x: x, y: y}, {x: cx, y: cy}, -angle);
                var pt1 = rotatePoint({x: e.clientX, y: e.clientY}, {x: cx, y: cy}, -angle);
                var deltaX = pt1.x - pt0.x;
                var deltaY = pt1.y - pt0.y;
                var nl = l + deltaX;
                var nt = t + deltaY;
                if (nl < r) {
                    nl = r
                };
                if (nl > 0) {
                    nl = 0
                };
                if (nt < b) {
                    nt = b
                };
                if (nt > 0) {
                    nt = 0
                };

                sticker.image.offsetx = nl/proW/sticker.image.scale;
                sticker.image.offsety = nt/proH/sticker.image.scale;
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isImagebox = false;
                vm.isImageboxTrim = false;
                stickerDragBox = {}
            }
        }
        //防止事件冒泡，默认事件
        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }
        /***********************  左侧菜单  ****************************/
        vm.nav = "picture";
        /***********************  照片  ****************************/
        vm.folderShow = false;
        vm.indexNum = 0;
        vm.maxId = null;
        vm.minId = null;
        vm.totalPhotoItems = null;
        vm.itemsPerPage = 40;
        $scope.photos = [];
        $scope.moveGalleryIds = [];
        vm.picLoading = false;
        vm.deleteSure = false;
        vm.folderIds = "";
        vm.folderId = "";
        vm.photosTab='all';
        function loadFolders() {
            var articleId = vm.article ? (vm.article.articleId || vm.article.id) : vm.articleId;
            Folder.storeGetAllByCondition({
                items: [{key: "type", op: "=", value: "Gallery"},{key: "articleId", op: "=", value: articleId}],
                sort: ["id,asc"]
            }, function (resp) {
                if(resp.status == 200){
                    $scope.Folders = resp.data;
                }else {
                    MessageService.error(resp.message);
                }
            })
        }
        $scope.folderOn = function () {
            vm.folderShow = !vm.folderShow;
        };
        // 增加文件夹
        $scope.addFolder = function () {
            $uibModal.open({
                templateUrl: "app/entities/page/pagesmySuffix-create.html",
                controller: "PageMySuffixCreateController",
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page)
                        };
                    }],
                    entity: [function() {
                        return {
                            type: "NEW"
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function (backData) {
                Folder.save({
                    name: backData,
                    type: 'Gallery',
                    articleId:vm.article.id
                }, function (resp) {
                    $scope.Folders.push(resp.data);
                })
            });
        }
        vm.folderName = ''
        //选择文件夹
        $scope.showFolderContent = function (folder) {
            vm.unusedQuantity = null;
            vm.oldQuantity = null;
            vm.indexNum = 0;
            vm.maxId = null;
            vm.minId = null;
            vm.totalPhotoItems = null;
            $scope.photos = [];
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            vm.folderId = folder ? folder.id : '';
            vm.folderName = folder ? folder.name : '';
            if (folder) {
                loadAll(folder.id);
            } else {
                loadAll('isNull');
            }

        };
        $scope.deleteShow = function (ids,event) {
            event.stopPropagation();
            vm.folderIds=ids;
            vm.deleteSure = true;
        }
        $scope.photoCb = function () {
            vm.indexNum++;
            loadAll('isNull');
        }
        $scope.updateFloders = function(folder,event){
            event.stopPropagation();
            $uibModal.open({
                templateUrl: 'app/entities/page/pagesmySuffix-create.html',
                controller: 'PageMySuffixCreateController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: [function() {
                        return {
                            type: "EDITOR",
                            name: folder.name
                        };
                    }]
                }
            }).result.then(function (data) {
                folder.name = data;
                Folder.update(folder, function (resp) {
                    if(resp.status == 200){
                        loadFolders();
                        vm.indexNum = 0;
                        vm.maxId = null;
                        vm.minId = null;
                        vm.totalPhotoItems = null;
                        $scope.photos = [];
                        loadAll('isNull');
                        MessageService.success($translate.instant("editorMessage.modifySuccess"));
                    }else {
                        MessageService.error(resp.message);
                    }
                },function () {
                    swal.showInputError($translate.instant("editorMessage.pleaseEnterTheCorrectFileName"));
                })
            }, function(){

            });

        }

        vm.photoSort = '1';
        $scope.photoSortChange = function (value) {
            vm.photoSort = value;
            vm.indexNum = 0;
            vm.maxId = null;
            vm.minId = null;
            vm.totalPhotoItems = null;
            $scope.photos = [];
            if(vm.folderId){
                loadAll(vm.folderId);
            }else{
                loadAll('isNull');
            }
        }
        function loadAll(folderId) {
            vm.picLoading = true;
            //$scope.gallaryPic = [];
            //$scope.moveGalleryIds = [];
            var sort = ["id,desc"];
            if(vm.photoSort == 1){
                sort = ["id,desc"]
            }else if(vm.photoSort == 2){
                sort = ["name,desc"]
            }else if(vm.photoSort == 3){
                sort = ["name,asc"]
            }
            var items = [];
            var articleId = vm.article ? (vm.article.articleId || vm.article.id) : vm.articleId;
            if (folderId == 'isNull') {
                items.push({key: "folderId", op: "isNull", value: ''})
            }else{
                items.push({key: "folderId", op: "=",value: folderId});
            }
            items.push({key: "articleId", op: "=", value: articleId})
            if (vm.maxId) {
                items.push({key: "id", op: "<=",value: vm.maxId});
            }
            if (vm.minId) {
                items.push({key: "id", op: "<",value: vm.minId});
            }
            if (folderId == 'isNull') {
                $scope.selectFolder = '已传照片';
                Gallery.storeByCondition({
                    items: items,
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sort
                }, onSuccess, onError);
            } else {
                Gallery.storeByCondition({
                    items: items,
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sort
                }, onSuccess, onError);
            }
        }
        function onSuccess(data, headers) {
            if (vm.totalPhotoItems == null) {
                vm.totalPhotoItems = Number(headers('X-Total-Count'));
            }
            if (vm.maxId == null && vm.photoSort == 1 && data.data.length > 0) {
                vm.maxId = data.data[0].id;
            }
            vm.picLoading = false;
            var len = $scope.photos.length;
            angular.forEach(data.data, function (item, i) {
                item.identifier = $scope.domain + item.identifier;
                item.index = i + len;
                item.usenum = 0;
                item.type = 'picture';
                if($scope.gallaryPic.some(function(item1){
                    item1.id === item.id
                })){
                    item.select = true
                }else{
                    item.select = false
                }
                $scope.photos.push(item);
            });
            photosNum();
            if($scope.photos.length < vm.totalPhotoItems){
                vm.isPhotoFinish = false;
            }else {
                vm.isPhotoFinish = true;
            }
            if($scope.photos.length > 0){
                newCreateDate = new Date($scope.photos[0].createdDate).getTime();
            }else {
                newCreateDate = new Date().getTime();
            }
        }

        function onError(error) {
            vm.picLoading = false;
            MessageService.error($translate.instant("editorMessage.failedToObtainThePhotoGallery"));
        }
        //删除多个文件夹
        $scope.deleteFolders = function () {
            vm.deleteSure = false;
            Folder.batchDelete({ids: vm.folderIds}, function () {
                vm.indexNum = 0;
                vm.maxId = null;
                vm.minId = null;
                vm.totalPhotoItems = null;
                vm.maxId = null;
                vm.minId = null;
                vm.totalPhotoItems = null;
                $scope.photos = [];
                loadAll('isNull');
                loadFolders();
                MessageService.success($translate.instant("editorMessage.deleteSuccess"));
            }, function () {
                MessageService.error($translate.instant("editorMessage.deleteError"));
            });
        };
        $scope.choosePhoto = function (photo) {
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            photo.select = !photo.select;
            angular.forEach($scope.photos, function (p, i) {
                if (p.select) {
                    $scope.gallaryPic.push(p);      //选择导入图片的数组
                    $scope.moveGalleryIds.push(p.id);
                }
            });
        }
        $scope.switchBgTab = function (attr) {
            vm.photosTab = attr;
            if(vm.photosTab!='all'){
                vm.unUsedImageboxsNum = 0;
                angular.forEach($scope.photos,function (p) {
                    if(p.usenum==0){
                        vm.unUsedImageboxsNum++;
                    }
                })
            }
            getGallery();
            $scope.whetherChoose(false);
        }
        $scope.whetherChoose = function (attr) {
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            $scope.photos.some(function (p, i) {
                if (attr) {
                    p.select = true;
                    $scope.gallaryPic.push(p);      //选择导入图片的数组
                    $scope.moveGalleryIds.push(p.id);
                } else {
                    p.select = false;
                    $scope.gallaryPic = [];      //选择导入图片的数组
                    $scope.moveGalleryIds = [];
                }
            })
        }
        $scope.deleteBatchPhotos = function () {
            if (!$scope.moveGalleryIds.length) {
                MessageService.error($translate.instant("editorMessage.pleaseSelectAPhoto"));
                return
            }
            Gallery.batchDelete($scope.moveGalleryIds, function () {
                MessageService.success($translate.instant("editorMessage.deleteSuccess"));
                // 若被选中的pic被删除，就重新生成该数组
                // vm.totalPhotoItems = vm.totalPhotoItems - $scope.moveGalleryIds.length
                vm.unusedQuantity = null;
                vm.oldQuantity = null;
                $scope.gallaryPic = [];
                $scope.moveGalleryIds = [];
                $scope.photos = [];
                //没有数据，页码置为0，重新调接口查询
                vm.indexNum = 0;
                vm.maxId = null;
                vm.minId = null;
                vm.totalPhotoItems = null;
                if (vm.folderId) {
                    loadAll(vm.folderId);
                } else {
                    loadAll('isNull');
                }
            }, function (error) {
                MessageService.error($translate.instant("editorMessage.deleteError"));
            });
        }
        //一键填充
        $scope.directFill= function(){
            if (vm.pictureType == 'spread' && $scope.gallaryPic.length % 2 === 1){
                MessageService.error($translate.instant("editorMessage.evenNumberImg"));
                return
            }
            for(var i=0; i<$scope.gallaryPic.length; i++){
                $scope.gallaryPic[i].seq = i+1;
            }
            abook($scope.gallaryPic);
        }
        function photosNum(){
            for(var i=0; i<$scope.photos.length; i++){
                var _photo = $scope.photos[i];
                _photo.usenum=0;
                angular.forEach(vm.data, function (data, index) {
                    if(vm.pictureType == 'spread'){
                        angular.forEach(data,function (page) {
                            angular.forEach(page.levels.level, function (level, i) {
                                if(level.imagebox && level.imagebox.image && level.imagebox.image.resourceid) {
                                    if (level.imagebox.image.resourceid == _photo.sourceId) {
                                        _photo.usenum++
                                    }
                                }
                            })
                        })
                    }else{
                        angular.forEach(data.levels.level, function (level, i) {
                            if(level.imagebox && level.imagebox.image && level.imagebox.image.resourceid) {
                                if (level.imagebox.image.resourceid == _photo.sourceId) {
                                    _photo.usenum++
                                }
                            }
                        })
                    }
                })
            }
            if(vm.photosTab!='all'){
                $scope.switchBgTab();
            }
            unusedQuantity();
        }
        // 背景图和素材的上传
        $scope.uploadPic = function () {
            uploadAble(StoreStorages,vm.storeId, function (res) {
                if(res.status == 200){
                    $("#uploadImg2").trigger("click");
                }else{
                    if(res.status == 400){MessageService.error('系统出错');}
                    else {
                        MessageService.error(res.data||'系统出错');
                    }
                }
            });

        }

        $scope.uploadImage = function(e){
            e.stopPropagation();
            e.preventDefault();
            if (!vm.product.editTip || !vm.product.editTipPic || $localStorage.dotRemindEditorTipPic) {
                $scope.uploadPic();
                return
            }
            $uibModal.open({
                templateUrl: 'app/entities/components/controls/editorTipPicModal.html',
                controller: 'EditorTipPicModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            url: vm.product.editTipPic,
                        };
                    },
                }
            }).result.then(function() {
                $scope.uploadPic();
            });
        }
        var bigSizePics = [];
        window.updatePic = function ($event) {
            vm.successNumber = 0;//上传成功数量
            vm.errorNumber = 0;//上传失败数量
            var oldlength = $event.target.files.length;
            var filterFilesList= filterFiles($event.target.files);
            var newlength = filterFilesList.length;
            if (newlength < oldlength) {
                MessageService.error($translate.instant("editorMessage.updatePicError"));
            }
            $timeout(function () {
                vm.picLoading = true;
                var len = $scope.photos.length;
                var files = [];
                var higherSize = [];
                bigSizePics = [];
                // vm.totalPhotoItems = (vm.totalPhotoItems - 0) + (filterFilesList.length-0)
                for (var i = 0; i < filterFilesList.length; i++) {
                    var f = filterFilesList[i];
                    f.imageSize = Math.ceil(f.size/1024/1024);
                    files.push(f)
                }
                if (files.length == 0) {
                    vm.picLoading = false;
                    return
                }
                for (var i = 0; i < files.length; i++) {
                    var file = files[i];
                    if (file.size>=20*1024*1024 ){
                        file.imageSize= Math.ceil(file.size/1024/1024);
                        bigSizePics.push(file);
                    }else{
                        getBgImg(files, i, len);
                        if (bigSizePics.length==0 && i == (files.length-1)) {
                            // vm.picLoading = false;
                            setTimeout(function () {
                                angular.forEach($scope.photos, function (item, j) {
                                    item.index = j;
                                });
                            }, 400)
                        }
                    }
                }
                if (bigSizePics.length >0) {
                    thumbnailatorPic();
                }
                $event.srcElement.value = "";
            })
        };
        var uploadFailedList = []; //临时存储上传失败图片
        function getBgImg(files, i, len) {
            CommonService.UpdateQiniuService(files, i, len, $scope.domain, vm.token, vm.folderId,(vm.article.articleId || vm.article.id),function (resp) {
                vm.successNumber++;
                if (vm.successNumber + vm.errorNumber >= files.length) {
                    vm.picLoading = false;
                    document.getElementById('uploadImg2').value = '';//上传完清空输入框
                    if (vm.errorNumber > 0) {
                        vm.errorAlertPopup = true;
                    }
                };
            },function(errorFile,executionCompleted){
                uploadFailedList.push(errorFile);
                vm.errorNumber++;
                if (vm.successNumber + vm.errorNumber >= files.length) {
                    $timeout(function(){
                        vm.picLoading = false;
                        vm.uploadFailedList = uploadFailedList;
                        uploadFailedList = [];
                        vm.errorAlertPopup = true;
                        document.getElementById('uploadImg2').value = '';//上传完清空输入框
                        $scope.$apply();
                    })
                };
            })
        }

        $scope.errorAlertPopupClose = function(){
            vm.errorAlertPopup = false;
            vm.uploadFailedList = [];
        }

        $scope.errorPhotoView = function(){
            $uibModal.open({
                templateUrl: 'app/entities/page-compontents/uploadFailedImageListPopup.html',
                controller: 'UploadFailedImageListPopupController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return vm.uploadFailedList;
                    }]
                }
            }).result.then(function (flag) {
                if (flag) {
                    var files = vm.uploadFailedList;
                    for (var i = 0; i < files.length; i++) {
                        getBgImg(files, i);
                    }
                    vm.successNumber = 0;//上传成功数量
                    vm.errorNumber = 0;//上传失败数量
                    vm.picLoading = true;
                    vm.errorAlertPopup = false;
                    vm.uploadFailedList = [];
                }else{
                    vm.errorAlertPopup = false;
                    vm.uploadFailedList = [];
                }
            });
        }

        function thumbnailatorPic(){
            $uibModal.open({
                templateUrl: 'app/entities/page-compontents/thumbnailator.html',
                controller: 'ThumbnailatorController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return {
                            files: bigSizePics,
                            aid:(vm.article.articleId || vm.article.id),
                            folderId:vm.folderId,
                            domain:$scope.domain,
                            token:vm.token
                        };
                    }]
                }
            }).result.then(function (values) {
                bigSizePics=[];
                vm.picLoading = false;
                // if(values){
                //     for (var j = 0; j < values.length; j++) {
                //         var value = values[j];
                //         value.bgImg = value.identifier;
                //         $scope.photos.unshift(value);
                //     }
                //     vm.picLoading = false;
                //     setTimeout(function () {
                //         angular.forEach($scope.photos, function (item, z) {
                //             item.index = z;
                //         });
                //     }, 400)
                // }
            }, function(){
                vm.picLoading = false;
            });
        }

        //转移照片
        $scope.moveToFolder = function () {
            if ($scope.gallaryPic.length < 1) {
                MessageService.error($translate.instant("editorMessage.pleaseSelectAPhoto"));
                return
            }
            vm.isMoveToFolder = true;
        }

        $scope.chooseFolder2 = function(){
            angular.forEach($scope.Folders, function (f, i) {
                f.selected = false;
            })
            vm.selectFolderId = -1;
        }
        $scope.chooseFolder = function (folder, index) {
            vm.selectFolderId = null;
            folder.selected = !folder.selected;
            angular.forEach($scope.Folders, function (f, i) {
                if (index != i) {
                    f.selected = false;
                }
            })
            if (folder.selected) {
                vm.selectFolderId = folder.id;
            }
        }
        $scope.saveMoveToFolder = function () {
            if (vm.selectFolderId == null) {
                MessageService.error($translate.instant("editorMessage.pleaseSelectAFolder"));
                return
            }
            $scope.moveGalleryIds = [];
            angular.forEach($scope.gallaryPic, function (p, i) {
                $scope.moveGalleryIds.push(p.id);
            })

            Gallery.moveFolder({
                galleries: $scope.moveGalleryIds,
                folderId: vm.selectFolderId === -1 ? null : vm.selectFolderId
            }, function () {
                angular.forEach($scope.Folders, function (f, i) {
                    f.selected = false;
                })
                $scope.gallaryPic = [];
                $scope.moveGalleryIds = [];
                vm.unusedQuantity = null;
                vm.oldQuantity = null;
                vm.indexNum = 0;
                vm.maxId = null;
                vm.minId = null;
                vm.totalPhotoItems = null;
                $scope.photos = [];
                vm.isMoveToFolder = false;
                if (vm.folderId) {
                    loadAll(vm.folderId);
                } else {
                    loadAll('isNull');
                }
            })
        }

        //手机传图
        vm.qrcodeShow = false;
        vm.fid = 0;
        var qrcode = null;
        var phonetimer = null;
        var newCreateDate = 0;
        $scope.uploadByPhone = function () {
            uploadAble(StoreStorages,vm.storeId, function (res) {
                if(res.status == 200){
                    vm.qrcodeShow = !vm.qrcodeShow;
                    if (qrcode != null) {
                        // qrcode.clear();
                        $('#qrcode1').html("");
                    }

                    //vm.folderId -----某一文件夹信息
                    if (vm.folderId == undefined || vm.folderId == null) {
                        vm.fid = 0;
                    } else {
                        vm.fid = vm.folderId;
                    }

                    Article.getPhonePicToken({id:(vm.article.articleId || vm.article.id)}, function(data){
                        // 手机传图二维码
                        qrcode = $('#qrcode1').qrcode({
                            render: "canvas",
                            width: 150,
                            height: 150,
                            text: '' + vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + vm.fid + "/gallery/" + (vm.article.articleId || vm.article.id)+'?auth='+data.data
                        });
                    },function(){
                    })
                    // // 手机传图二维码
                    // qrcode = $('#qrcode1').qrcode({
                    //     render: "canvas",
                    //     width: 150,
                    //     height: 150,
                    //     text: '' + vm.platformSetting.storeNotifyUrl + "/#/" + vm.storeId + "/" + vm.fid + "/gallery/" + vm.userId
                    // });
                    // // (new Date("2012/12/25 20:11:11")).getTime()

                    // if (vm.qrcodeShow) {
                    //     phonetimer = setInterval(function () {
                    //         var gallery_items = [{key: "storeId", op: "=", value: vm.storeId},{key: "articleId", op: "=", value: vm.aid}];
                    //         if (vm.folderId) {
                    //             gallery_items.push({key: "folderId", op: "=", value: vm.folderId});
                    //         } else {
                    //             gallery_items.push({key: "folderId", op: "isNull", value: 1});
                    //         }
                    //         Gallery.storeByCondition({
                    //             items: gallery_items,
                    //             page: 0,
                    //             size: vm.itemsPerPage,
                    //             sort: ["createdDate,desc"]
                    //         }, onSuccessToPhone, onError);
                    //     }, 3000);
                    // } else {
                    //     clearInterval(phonetimer);
                    //     phonetimer = null;
                    // }
                }else{
                    if(res.status == 400){MessageService.error('系统出错');}
                    else {
                        MessageService.error(res.data||'系统出错');
                    }
                }
            })
        }

        function onSuccessToPhone(res) {
            var data = res.data;
            if (data == null || data.length == 0) {
                return;
            }
            var loadCreateDate = new Date(data[0].createdDate).getTime();
            for (var i = 0; i < data.length; i++) {
                var d = data[i];
                if (new Date(d.createdDate).getTime() > newCreateDate) {
                    d.identifier = $scope.domain + d.identifier;
                    d.select = false;
                    d.index = i;
                    $scope.photos.unshift(d);
                } else {
                    newCreateDate = loadCreateDate;
                    break;
                }
            }
            if (newCreateDate == 0) {
                newCreateDate = loadCreateDate;
            }
        }
        $scope.uploadByPhone1 = function(){
            vm.qrcodeShow = false;
            $scope.photoSortChange(vm.photoSort)
            // clearInterval(phonetimer);
        }
        /***********************  照片结束  ****************************/


        //全屏
        $scope.toggleFullScreen = function(){
            if (!vm.fullFlag) {
                //进入全屏
                var de = document.documentElement;
                if (de.requestFullscreen) {
                    de.requestFullscreen();
                } else if (de.mozRequestFullScreen) {
                    de.mozRequestFullScreen();
                } else if (de.webkitRequestFullScreen) {
                    de.webkitRequestFullScreen();
                }
                vm.fullFlag = true;
            } else {
                //退出全屏
                var de = document;
                if (de.exitFullscreen) {
                    de.exitFullscreen();
                } else if (de.mozCancelFullScreen) {
                    de.mozCancelFullScreen();
                } else if (de.webkitCancelFullScreen) {
                    de.webkitCancelFullScreen();
                }
                vm.fullFlag = false;
            }
        }
        //删除
        $scope.deleteBox = function ($event) {
            vm.data.splice(vm.pageIndex, 1);
            vm.pageSum = vm.data.length - 1 < 0 ? 0 : vm.data.length - 1;
            if(vm.pageIndex > vm.pageSum){
                vm.pageIndex = vm.pageSum;
            }
            photosNum();
        }
        $scope.subtractDeleteTab = function($event, index){
            fn($event)
            vm.data.splice(index, 1);
            vm.pageSum = vm.data.length - 1 < 0 ? 0 : vm.data.length - 1;
            if(vm.pageIndex > vm.pageSum){
                vm.pageIndex = vm.pageSum;
            }
            photosNum();
        }

        $scope.subtractQuantityTab = function ($event, data) {
            fn($event)
            if(data.quantity>1){
                data.quantity--;
            }
        }
        $scope.pushQuantityTab = function ($event, data) {
            fn($event)
            data.quantity++;
        }
        //复制背面
        vm.isCopyBack = false;
        vm.copyBackIndex == null;
        $scope.copyBack = function($event, index){
            fn($event)
            vm.isCopyBack = true;
            vm.copyBackIndex = index;
            console.log(vm.copyBackIndex)
        }
        $scope.copyHide = function(){
            vm.isCopyBack = false;
            vm.copyBackIndex == null;
        }
        $scope.copyAll = function(){
            vm.data.forEach(function(data,dataIndex){
                if(dataIndex != vm.copyBackIndex){
                    data[1] = JSON.parse(JSON.stringify(vm.data[vm.copyBackIndex][1]));
                    data[1].uuid = uuid();
                }
            })
            MessageService.success("复制成功")
            $scope.copyHide();
            photosNum();
        }
        $scope.editorPageCopyHere = function(index){
            vm.data[index][1] = JSON.parse(JSON.stringify(vm.data[vm.copyBackIndex][1]));
            vm.data[index][1].uuid = uuid();
            MessageService.success("复制成功")
            photosNum();
        }
        //保存
        $scope.isSave = false;
        vm.isPageWarnExamine = false;
        $scope.save = function () {
            articleOverdue(function () {
                if(vm.data.length == 0 || !vm.data){
                    MessageService.error("请添加照片")
                    return;
                }
                var _data = [];
                var _data1 = angular.copy(vm.data);
                var pageCount = 0;
                if(vm.pictureType == 'single'){
                    angular.forEach(_data1, function (item) {
                        for(var i=0; i<item.quantity; i++){
                            var itemData = angular.copy(item);
                            _data.push(itemData)
                        }
                    });
                    _data.forEach(function (data) {
                        data.quantity = 1;
                    })
                    pageCount = _data.length;
                }
                if(vm.pictureType == 'spread'){
                    _data1.forEach(function (data) {
                        for(var i=0; i<data[0].quantity; i++){
                            _data.push(data[0])
                            _data.push(data[1])
                        }
                    })
                    _data.forEach(function (data) {
                        data.quantity = 1;
                    })
                    pageCount = _data.length/2;
                }
                vm.article.articleStatus = "EDITING";
                if (vm.article.multiple) {//先编辑
                    if(vm.article.expectPageCount && vm.article.expectPageCount >= 0){
                        if(pageCount % vm.article.expectPageCount === 0){
                            vm.article.articleStatus = "COMPLETED";
                        }else{
                            MessageService.hint('当前冲印张数为' + pageCount+ ',总数量需要为'+vm.article.expectPageCount+'的倍数才可下单');
                            return
                        }
                    }else{
                        vm.article.articleStatus = "COMPLETED";
                    }
                }else{//先下单
                    if(vm.article.expectPageCount && vm.article.expectPageCount >= 0){
                        if(pageCount > vm.article.expectPageCount){
                            MessageService.hint('照片数量不可超过'+vm.article.expectPageCount+'份，请进行删减后保存');
                            return
                        }
                        if(pageCount == vm.article.expectPageCount){
                            vm.article.articleStatus = "COMPLETED";
                        }
                    }else{
                        vm.article.articleStatus = "COMPLETED";
                    }
                }


                if(!vm.isPageWarnExamine && pageWarnExamine(_data, vm.imgSize, vm.imgDpi)){
                    MessageService.confirm({
                        title:$translate.instant("editorCommon.hint"),
                        cancel:$translate.instant("button.amend"),
                        confirm:$translate.instant("button.continueSave"),
                        msg:$translate.instant("editorMessage.lackPixelsMessage")
                    },function(){
                        vm.isPageWarnExamine = true;
                        $scope.save();
                    })
                    return
                }
                //判断是否有图片未上传  且是否继续保存
                for (var i = 0; i < _data.length; i++) {
                    var _page = _data[i];
                    if (_page.levels.level) {
                        for (var j = 0; j < _page.levels.level.length; j++) {
                            var _level = _page.levels.level[j];
                            if (_level.imagebox && _level.imagebox.image) {
                                if (!_level.imagebox.image.resource || !_level.imagebox.image.resource.identifier) {
                                    MessageService.hint('有照片框未填充照片，无法保存');
                                    return false
                                }
                            }
                        }
                    }
                }
                if(!vm.placeOrder){
                    return
                }
                save1(_data);
            })
        }
        function articleOverdue(callback){
            if($stateParams.aid){
                Article.get({id: $stateParams.aid}, function (article) {
                    if(article.data.fileName != vm.article.fileName){
                        MessageService.confirm({
                            title:$translate.instant("editorCommon.hint"),
                            cancel:$translate.instant("button.cancel"),
                            confirm:$translate.instant("button.refresh"),
                            msg:$translate.instant("hint.articleOverdue")
                        },function(){
                            $state.go("editorPhotoBothWebEdit", {storeId: vm.storeId, aid:  vm.article.id},{reload:true});
                        })
                    }else{
                        callback();
                    }
                })
            }
            if($stateParams.orderArticleId) {
                OrderArticle.getByUser({id: $stateParams.orderArticleId}, function (article) {
                    if(article.data.fileName != vm.article.fileName){
                        MessageService.confirm({
                            title:$translate.instant("editorCommon.hint"),
                            cancel:$translate.instant("button.cancel"),
                            confirm:$translate.instant("button.refresh"),
                            msg:$translate.instant("hint.articleOverdue")
                        },function(){
                            $state.go("editorPhotoBothWebEdit1", {storeId: vm.storeId, orderArticleId:  $stateParams.orderArticleId},{reload:true});
                        })
                    }else{
                        callback();
                    }
                })
            }
        }
        function save1(data){
            vm.loading = true;
            thumbnailInit1(function (){
                if(data && data.length > 0){
                    var _page = [];
                    angular.forEach(data, function (item) {
                        var itemData = angular.copy(item);
                        _page.push(itemData)
                    });
                    var _template = angular.copy($scope.template);
                    _template.pages.page = _page;
                    _template = documentMM(_template, null, null);
                    $scope.isSave = true;
                    _template.hostinfo = hostInfo();
                    Convert.articleUploadDocument(_template, function (res) {
                        vm.article.productType = 'CustomPrint';
                        vm.article.fileName = res.data.identifier;
                        vm.article.unFilledCount = 0;
                        vm.article.version = '2.0';
                        vm.article.pageCount = 0;
                        _page.forEach(function (itemPage) {
                            if(!itemPage.backup){
                                vm.article.pageCount++
                            }
                        })
                        if($stateParams.aid){
                            vm.article.id = vm.aid;
                            Article.update(vm.article, function (resp) {
                                $scope.isSave = false;
                                vm.loading = false;
                                swal({
                                    title: $translate.instant("editorMessage.modifySuccess"),
                                    text: $translate.instant("editorMessage.synchronizationOfWorks"),
                                    imageUrl: Theme.sweetSuccedImg(),
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                                $(".wrap").show();
                                $(".navbar").show();
                                var screenW = document.body.clientWidth;
                                //web 端
                                if(screenW>1000){
                                    $state.go("page-view", {storeId: vm.storeId, aid:  resp.data.id});
                                }else{
                                    $state.go("editorPhotoPreview", {storeId: vm.storeId, aid:  resp.data.id});
                                }
                            }, function () {
                                $scope.isSave = false;
                                vm.loading = false;
                                swal({
                                    title: $translate.instant("editorMessage.saveError"),
                                    text: $translate.instant("editorMessage.saveError1"),
                                    imageUrl: Theme.sweetHintImg(),
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                            })
                        }else if($stateParams.orderArticleId){
                            OrderArticle.update(vm.article, function (resp) {
                                $scope.isSave = false;
                                vm.loading = false;
                                swal({
                                    title: $translate.instant("editorMessage.modifySuccess"),
                                    text: $translate.instant("editorMessage.synchronizationOfWorks"),
                                    imageUrl: Theme.sweetSuccedImg(),
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                                $(".wrap").show();
                                $(".navbar").show();
                                $state.go("page-view2", {storeId: vm.storeId, orderArticleId:  resp.data.id});
                            }, function () {
                                $scope.isSave = false;
                                vm.loading = false;
                                swal({
                                    title: $translate.instant("editorMessage.saveError"),
                                    text: $translate.instant("editorMessage.saveError1"),
                                    imageUrl: Theme.sweetHintImg(),
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                            })
                        }
                    }, function () {
                        $scope.isSave = false;
                        vm.loading = false;
                        swal({
                            title: $translate.instant("editorMessage.saveError"),
                            text: $translate.instant("editorMessage.saveError1"),
                            imageUrl: Theme.sweetHintImg(),
                            timer: 2000,
                            showConfirmButton: false
                        });
                    })
                }else{
                    vm.loading = false;
                }
            })
        }
        $scope.exitWork = function (e) {
            vm.exitSure = true;
        }
        $scope.exitPage = function () {
            localStorage.removeItem("photoSelectAll");
            if (vm.pid) {
                $(".wrap").show();
                $(".navbar").show();
                $state.go("commoditySelect", {
                    storeId: vm.storeId,
                    pid: vm.pid,
                    vid: vm.vid,
                    aid: vm.aid,
                }, {reload: true});
            } else {
                window.location.href = localStorage.getItem('returnurl');
            }
        }
        //像素不足提醒
        vm.imgSize = 150;
        vm.imgDpi = 150;
        StoreWorkEditConfig.storeId({storeId: vm.storeId}, function (res) {
            if(res.status == 200){
                vm.imgSize = res.data.imgSize;
                vm.imgDpi = res.data.imgDpi;
            }
        });
        var throttledResize = angular.throttle(function (item) {
            console.log('No browser drain at each event please.');
            return pxInsufficient(item, vm.imgSize, vm.imgDpi)
        }, 1000);

        $scope.pxInsufficient = function (item) {
            // return throttledResize(item);
            return pxInsufficient(item, vm.imgSize, vm.imgDpi);
        }
        //边框
        $scope.imageboxW = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.width
                    // return Number(imagebox.geometry.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100)
                }
            } else {
                return imagebox.geometry.width
            }
        }
        $scope.imageboxH = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.height
                    // return Number(imagebox.geometry.height) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100)
                }
            } else {
                return imagebox.geometry.height
            }
        }
        $scope.imageboxL = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.x
                    // return Number(imagebox.geometry.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
                }
            } else {
                return imagebox.geometry.x
            }
        }
        $scope.imageboxT = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.y
                    // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                }
            } else {
                return imagebox.geometry.y
            }
        }
        $scope.imageboxWEdge = function (imagebox, edge) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    if (edge == 'left') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100
                    }
                    if (edge == 'top') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100
                    }
                    if (edge == 'right') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100
                    }
                    if (edge == 'bottom') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100
                    }
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return _radius > 0 ?  (_radius + Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius1 = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return  (_radius - Number(imagebox.border.lineWidth)/2) > 0 ? (_radius - Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return (_r - Number(imagebox.border.lineWidth)/2) > 0 ? (_r - Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.dropShadow = function(imagebox,ratio,ratio1){
            if (imagebox.shadow == null) {
                return
            }
            var width = $scope.imageboxW(imagebox) * ratio;
            var height =$scope.imageboxH(imagebox) * ratio1;
            return dropShadow(imagebox,width,height)
        }
        $scope.editorPageDialog = function (index,i) {
            vm.editorPageList = [];
            if(vm.pictureType == 'single'){
                vm.editorPageList = vm.data;
                vm.pageIndex = index+i;
            }
            if(vm.pictureType == 'spread'){
                vm.data.forEach(function (data) {
                    data.forEach(function (page) {
                        vm.editorPageList.push(page)
                    })
                })
                vm.pageIndex = index*2+i;
            }
            if(vm.article.editorType == 'PICTURE_PRO'){
                vm.editorDialog1 = true;
            }else{
                vm.editorDialog = true;
                vm.levelIndex = 0;
            }

        }
        $scope.pagePhotoCb = function (text){
            vm.editorDialog1 = false;
            if(text){
                if(vm.pictureType == 'single'){
                    vm.data[vm.pageIndex] = JSON.parse(JSON.stringify(text))
                }
                if(vm.pictureType == 'spread'){
                    var i=0;
                    vm.data.forEach(function (data) {
                        for(var j=0;j<2;j++){
                            if(i == vm.pageIndex){
                                data[j] = JSON.parse(JSON.stringify(text))
                            }
                            i++
                        }
                    })
                }
                photosNum();
            }
            ondragenter();
        }

        $scope.zfInit = function(){
            if (vm.pageIndex % 2 === 0){
                return false
            } else if (vm.pageIndex % 2 === 1){
                return true
            }
        }
        //图片框缩放
        window.bbimg = function (delta) {
            if (!vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex] || vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.bokehtype != 'None') {
                return
            }
            var _image = angular.copy(vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image);
            if (delta < 0 || delta == undefined && event.wheelDelta > 0) {
                vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.scale *= 1.01;
            } else {
                vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.scale /= 1.01;
                if (vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.scale <= 1) {
                    vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.scale = 1;
                }
            }
            ImageboxCb(_image, vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox, vm.data[vm.pageIndex]);
            $scope.$apply();
            return false;
        };
        //图片框的操作
        $scope.imageboxSite = function (item) {
            if (!vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex] || vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.bokehtype != 'None') {
                return
            }
            var _imagebox = vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            if(item == 'center'){
                _imagebox.image.offsetx = -(_imagebox.image.width*_imagebox.image.scale - _imagebox.geometry.width)/2/_imagebox.image.scale;
                _imagebox.image.offsety = -(_imagebox.image.height*_imagebox.image.scale - _imagebox.geometry.height)/2/_imagebox.image.scale;
            }
            if(_imagebox.image.offsetx > 0){
                _imagebox.image.offsetx = 0;
            }
            if(_imagebox.image.offsety > 0){
                _imagebox.image.offsety = 0;
            }
        }
        vm.isWatchName = false;
        $scope.watchGalleryName = function(state){
            vm.isWatchName = state;
            // setTimeout(function () {
            //     waterFallPic('main5');
            //     $(".photo-imgs").height($scope.photoHight);
            // }, 200);
        }

        $('#navId').on('scroll', function(event){
            if(vm.nav=='picture'){
                var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
                if($scope.photos.length < vm.totalPhotoItems){
                    if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                        vm.indexNum++;
                        if(vm.folderId){
                            loadAll(vm.folderId);
                        } else {
                            loadAll('isNull');
                        }
                    }
                }
            }
        });
        //拖拽图片
        ondragenter();
        function ondragenter(){
            $timeout(function () {
                var content = $(".editor-photo-body")[0]
                if(content){
                    content.ondragenter = ignoreDrag;
                    // 拖动文件的鼠标指针位置放置区之上时发生
                    content.ondragover = ignoreDrag;
                    content.ondrop = drop;
                }
            },1000)

            window.dragStart = function (event) {
                var target = event.target;
                event.dataTransfer.setData("img", target.id);
                console.log(target)
                $timeout(function () {
                    if(target.id.indexOf('dragtargetPhoto')>-1){
                        vm.dragDom = true;
                    }
                    if(target.id.indexOf('reservePage')>-1){
                        vm.dragDom1 = true;
                    }
                })
            };
            window.dragOn = function(event){
                $timeout(function () {
                    vm.dragDom = false;
                    vm.dragDom1 = false;
                    $(".imageboxView").removeClass('selected-blue')
                    $(".c-page-view-li-img").removeClass('selected-blue');
                })
            }
            $timeout(function () {
                document.body.ondrop = function(event) {
                    event.preventDefault();
                    event.stopPropagation();
                };
            })
        }
        function ignoreDrag(e) {
            // 确保其他元素不会取得该事件
            e.stopPropagation();
            e.preventDefault();
            var dataset = e.target.dataset;
            if ((vm.dragDom && (e.target.nodeName == 'IMG' || ($(e.target).attr('class') && $(e.target).attr('class').indexOf('image-placeholder-class') > -1))&&dataset.imgindex) || (vm.dragDom1 && $(e.target).parents(".c-page-view-li-img"))) {
                var textIndex = dataset.imgindex;
                if(vm.dragDom){
                    $(".imageboxView").removeClass('selected-blue')
                    var pageIndex2 = $(e.target).parents('.c-page-view-li-img').attr('page-index2');
                    $(".imageboxView"+pageIndex2+textIndex).addClass('selected-blue');
                    console.log(pageIndex2+textIndex)
                    return
                }
                if(vm.dragDom1){
                    $(".c-page-view-li-img").removeClass('selected-blue');
                    $(e.target).parents(".c-page-view-li-img").addClass('selected-blue');
                    return
                }
            }else{
                $(".imageboxView").removeClass('selected-blue')
                $(".c-page-view-li-img").removeClass('selected-blue');
            }
        }
        vm.replacePageIndex = 0;
        vm.replacePageArrIndex = 0;
        vm.replaceTextIndex = 0;
        vm.replacePageIndex2 = 0;
        function drop(e) {
            var data = e.dataTransfer.getData("img");
            $timeout(function () {
                console.log(vm.dragDom)
                console.log(vm.dragDom1)
                $(".imageboxView").removeClass('selected-blue');
                $(".c-page-view-li-img").removeClass('selected-blue');
                if(vm.dragDom){
                    var _pageArrIndex = $(e.target).parents('.c-page-view-li-img').attr('data-pageArrIndex');
                    var _pageIndex = $(e.target).parents('.c-page-view-li-img').attr('data-pageIndex');
                    var _pageIndex2 = $(e.target).parents('.c-page-view-li-img').attr('page-index2');
                    var dataset = e.target.dataset;
                    var textIndex = dataset.imgindex;
                    vm.replacePageIndex = _pageIndex;
                    vm.replacePageArrIndex = _pageArrIndex;
                    vm.replaceTextIndex = textIndex;
                    vm.replacePageIndex2 =_pageIndex2;
                    if(!_pageIndex && !_pageIndex2){
                        return
                    }
                    ignoreDrag(e);
                    //拖拽图片进入占位框
                    if (data.indexOf('dragtargetPhoto') != '-1') {
                        var imgindex = e.target.dataset.imgindex;
                        if(imgindex == null){
                            return
                        }else {
                            vm.levelIndex = imgindex;
                            var item = JSON.parse($('#' + data).attr('data-item'));
                            // var _imagebox = vm.data[_pageArrIndex][_pageIndex].levels.level[vm.levelIndex].imagebox;
                            // var identifier = _imagebox.mask.resource.identifier;
                            replaceImagebox(item);
                            $timeout(function () {
                                photosNum();
                            },500)
                        }
                    }
                }
                if(vm.dragDom1){
                    var item = JSON.parse($('#' + data).attr('data-item'));
                    var pageIndex = $(e.target).parents('.c-page-view-li-img').attr('data-pageArrIndex');
                    var pageNumber = $(e.target).parents('.c-page-view-li-img').attr('data-pageindex') || null;
                    layoutCb(item, pageIndex, pageNumber)
                }
            })
        }

        function replaceImagebox(data){
            var item = angular.copy(data);
            if(vm.pictureType == 'single'){
                var _imagebox = vm.data[vm.replacePageIndex2].levels.level[vm.replaceTextIndex].imagebox;
            }else{
                var _imagebox = vm.data[vm.replacePageArrIndex][vm.replacePageIndex].levels.level[vm.replaceTextIndex].imagebox;
            }
            if(!_imagebox.bokehtype){
                _imagebox.bokehtype = 'None';
            }
            _imagebox.image = {
                resource: {
                    identifier: item.identifier,
                    provider: 'qiniu'
                },
                filterResource: {
                    identifier: '',
                    provider: 'qiniu'
                },
                offsetx:0,
                offsety:0,
                resourceid:item.sourceId,
                width: item.width,
                height: item.height,
                imageInfo: item.imageInfo,
                imgSize: item.imgSize,
                index: item.seq,
                parameter: angular.fromJson(item.parameter),
                scale: 1,
                angle: 0,
            }
            if(vm.pictureType == 'single'){
                getBlurredrectangle(_imagebox,0)
            }else{
                getBlurredrectangle(_imagebox,vm.replacePageIndex)
            }
        }

        //生成封面图
        function thumbnailInit1(callback) {
            $timeout(function (){
                html2canvas_adapt(document.getElementById('thumbnail-view1'), {
                    onrendered: function (canvas) {
                        var url = canvas.toDataURL("image/png");
                        var blob = dataURLtoBlob(url);
                        var formData = new FormData();
                        formData.append('file', blob);
                        formData.append('key', '3dThumbnail-' + UidService.get());
                        formData.append('token', vm.token);
                        $.ajax({
                            cache: true,
                            type: "POST",
                            url: 'https://upload.qiniup.com/',
                            data: formData,
                            async: false,
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                var identifier = data.key;
                                vm.article.covered=true;
                                vm.article.cover=identifier;
                                console.log(vm.article.cover);
                                if(callback){callback()};
                            }
                        })
                    },
                    letterRendering: true,
                    useCORS: true,
                    width: vm.pageWidth * vm.ratio,
                    height: vm.pageHeight * vm.ratio,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: document.body.scrollHeight,
                    x: 0,
                    y: 0,
                    backgroundColor: "rgba(0, 0, 0, 0)"
                });
            })
        }

        /***********************  版式  ****************************/
        function layoutCb(item, pageIndex, pageNumber){
            vm.loading = true;
            var layoutPage = angular.copy(item);
            layoutPage.backup = false;
            layoutPage.unreplace = false;
            pageDataInfo(layoutPage,pageIndex,pageNumber)
        }
        function pageDataInfo(page, pageIndex,pageNumber) {
            if(vm.pictureType == 'spread'){
                var _pageData = angular.copy(vm.data[pageIndex][pageNumber]);
            }else{
                var _pageData = angular.copy(vm.data[pageIndex]);
            }
            var _pageDataImageboxImage = [];
            var _pageDataImageboxImageSum = 0;
            for(var i=0; i<_pageData.levels.level.length; i++){
                var _lecel = _pageData.levels.level[i];
                if(_lecel.imagebox && _lecel.imagebox.image && _lecel.imagebox.image.resource && _lecel.imagebox.image.resource.identifier){
                    _lecel.imagebox.imgRatio = _lecel.imagebox.image.width/_lecel.imagebox.image.height;
                    _pageDataImageboxImage.push(_lecel.imagebox)
                }
            }
            if(!page.levels.level){
                page.levels.level=[];
            }
            _pageDataImageboxImage = imageboxAdapt(page.levels.level, _pageDataImageboxImage);
            angular.forEach(page.levels.level, function (level, i) {
                if (level.imagebox) {
                    var _imagebox = _pageDataImageboxImage[0];
                    for(var j=0; j<_pageDataImageboxImage.length; j++){
                        if(_pageDataImageboxImage[j].imageboxIndex == _pageDataImageboxImageSum){
                            _imagebox = _pageDataImageboxImage[j];
                            break
                        }
                    }
                    _pageDataImageboxImageSum++;
                    if (_imagebox) {
                        level.imagebox.bokehtype = level.imagebox.bokehtype || vm.article.bokehtype || 'None';
                        level.imagebox.image = {
                            blurredrectangle: {
                                width: 0,
                                height: 0,
                                x: 0,
                                y: 0
                            },
                            resource: {
                                identifier: _imagebox ? _imagebox.image.resource.identifier : '',
                                provider: _imagebox ? _imagebox.image.resource.provider : 'qiniu'
                            },
                            resourceid:_imagebox ? _imagebox.image.resourceid : '',
                            filterResource: {
                                identifier: '',
                                provider: 'qiniu'
                            },
                            angle: 0,
                            offsetx: 0,
                            offsety: 0,
                            imageInfo: _imagebox? _imagebox.image.imageInfo : '',
                            width: _imagebox ? _imagebox.image.width : '',
                            height: _imagebox ? _imagebox.image.height : '',
                            imgSize: _imagebox ? _imagebox.image.imgSize : '',
                            index: _imagebox ? _imagebox.image.seq : '',
                            parameter: _imagebox ? angular.fromJson(_imagebox.image.parameter) : [],
                            scale: 1
                        }
                        if (level.imagebox.image.resource.identifier) {
                            getBlurredrectangle(level.imagebox,(pageNumber||0))
                            // addImagebox($http, level.imagebox, function () {})
                            // getBackgroundSource3(level.imagebox, level.imagebox.geometry.width, level.imagebox.geometry.height, level.imagebox.image, level.imagebox.bokehtype, $scope)
                        }
                    } else {
                        level.imagebox.bokehtype = level.imagebox.bokehtype || vm.article.bokehtype || 'None';
                        level.imagebox.image = {
                            resource: {
                                identifier: '',
                                provider: ''
                            },
                            resourceid:"",
                            filterResource: {
                                identifier: '',
                                provider: ''
                            },
                            width: '',
                            height: '',
                            imgSize: '',
                            offsetx: 0,
                            offsety: 0,
                            index: '',
                            parameter: [],
                            scale: 1,
                            angle: 0,
                        }
                    }
                }
            })
            $timeout(function () {
                if(vm.pictureType == 'spread'){
                    vm.data[pageIndex][pageNumber] = page;
                }else{
                    vm.data[pageIndex] = page;
                }
                photosNum();
                vm.loading = false;
                MessageService.success($translate.instant("editorMessage.formatReplacementSucceeded"));
            },200)
        }
        /***********************  版式end  ****************************/


        // 计算图片使用数量
        vm.unusedQuantity = null;
        function unusedQuantity(){
            var galleryIdList = [];
            angular.forEach(vm.data, function (data, i) {
                if(vm.pictureType == 'spread'){
                    angular.forEach(data,function (page) {
                        angular.forEach(page.levels.level, function (level, i) {
                            if(level.imagebox && level.imagebox.image && level.imagebox.image.resourceid) {
                                galleryIdList.push(level.imagebox.image.resourceid)
                            }
                        })
                    })
                }else{
                    angular.forEach(data.levels.level, function (level, i) {
                        if(level.imagebox && level.imagebox.image && level.imagebox.image.resourceid) {
                            galleryIdList.push(level.imagebox.image.resourceid)
                        }
                    })
                }
            })

            var newArr = galleryIdList.filter(function(item,index){
                return galleryIdList.indexOf(item) === index;  // 因为indexOf 只能查找到第一个
            });
            Gallery.unUsed({
                galleryIdList:newArr,
                folderId: vm.folderId,
                articleId: (vm.article.articleId || vm.article.id)
            },function (res) {
                if(res.status == 200){
                    vm.unusedQuantity = res.data;
                    getGallery();
                }
            })
        }
        // 切换未使用tab后数量不够分页加载下一页
        function getGallery(){
            if(vm.photosTab!='all' && $scope.photos.length < vm.totalPhotoItems){
                var _photoQuantity = 0;
                $scope.photos.forEach(function (item) {
                    if(item.usenum==0||!item.usenum){
                        _photoQuantity++
                    }
                })
                if(_photoQuantity < vm.itemsPerPage){
                    vm.indexNum++;
                    if(vm.folderId){
                        loadAll(vm.folderId);
                    }else{
                        loadAll('isNull');
                    }
                }
            }
        }
        // websocket接收上传的图片（多端互通实时更新）
        function websocket(){
            var urlws = vm.platformSetting.editorNotifyUrl.split("://")[1];
            urlws = urlws.split("/")[0];
            var ws = $websocket("wss://"+ urlws +"/widget/ws/websocket?uid=Gallery"+(vm.article ? (vm.article.articleId || vm.article.id) : vm.articleId));

            ws.onOpen(function() {
                console.log('WebSocket连接已打开, url:'+ws.url);
            });

            ws.onMessage(function(message) {
                var photo = JSON.parse(message.data);
                // console.log(photo);
                if (photo.folderId == vm.folderId || (photo.folderId == null && vm.folderId == '')) {
                    vm.totalPhotoItems++;
                    vm.unusedQuantity++;//增加未使用数
                    var len = $scope.photos.length;
                    photo.identifier = $scope.domain + photo.identifier;
                    photo.select = false;
                    photo.index = len;
                    photo.usenum = 0;
                    photo.type = 'picture';
                    $scope.photos.unshift(photo);
                    if (vm.photoSort != 1 && vm.minId==null) {
                        vm.minId = photo.id;
                    }
                }
            });

            ws.onError(function(error) {
                console.log('WebSocket错误：', error);
            });

            ws.onClose(function() {
                console.log('WebSocket连接已关闭');
            });
        }

        $scope.getTotalCountText = getTotalCountText;
        function getTotalCountText(){
            var num = getTotalCount();
            var expectPageCount = vm.article ? vm.article.expectPageCount : 0;
            var text = '';
            if(vm.article){
                if (vm.article.expectPageCount>1) {
                    text = num + ' / ' + expectPageCount;
                } else {
                    text = num;
                }
            }
            return text;
        }

        function getTotalCount(){
            var num = 0;
            if (vm.pictureType == 'spread') {
                vm.data.forEach(function(page){
                    if (page[0].quantity) {
                        num += Number(page[0].quantity);
                    }
                })
            }else {
                vm.data.forEach(function(page){
                    if (page.quantity) {
                        num += Number(page.quantity);
                    }
                })
            }
            return num;
        }

    }
})();
