(function () {
    'use strict';

    angular
        .module('editorApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('articlesFailure',{
                parent:'entity',
                url:'/{storeId}/articlesFailure',
                data:{
                    authorities: [],
                    pageTitle: ''
                },
                views:{
                    'navbar@': {
                        templateUrl: 'app/entities/page/articles-failure.html',
                        params:{'tips':null},
                        controller: 'Page404Controller',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    tips: {

                    }
                }
            })
            .state('404',{
                parent:'entity',
                url:'/{storeId}/404',
                data:{
                    authorities: [],
                    pageTitle: ''
                },
                views:{
                    'navbar@': {
                        templateUrl: 'app/entities/page/page-404.html',
                        params:{'tips':null},
                        controller: 'Page404Controller',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    tips: {

                    }
                }
            })
            .state('page-view', {
                parent: 'entity',
                url: '/{storeId}/webView/{aid}',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.viewTitle'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/page/page-view-article.html',
                        controller: 'PageViewArticleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    // entity: ['$stateParams', 'Article', function ($stateParams, Article) {
                    //     return Article.get({id: $stateParams.aid}).$promise;
                    // }]
                }
            })
            .state('page-view2', {
                parent: 'entity',
                url: '/{storeId}/webView2/{orderArticleId}?&authorization',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.viewTitle'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/page/page-view-article.html',
                        controller: 'PageViewArticleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    // entity: ['$stateParams', 'Article', function ($stateParams, Article) {
                    //     return Article.get({id: $stateParams.orderArticleId}).$promise;
                    // }]
                }
            })
            .state('editorPhotoWeb', {
                parent: 'entity',
                url: '/{storeId}/print/{pid}/{vid}/{xmlId}/{aid}',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/page/editor-photo-both-web.html',
                        controller: 'EditorPhotoBothWebController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            type: 'add'
                        };
                    }
                }
            })
            .state('editorPhotoWebEdit1', {
                parent: 'app',
                url: '/{storeId}/{orderArticleId}/printOrder?authorization',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/page/editor-photo-both-web.html',
                        controller: 'EditorPhotoBothWebController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            type: 'edit'
                        };
                    }
                }
            })
            .state('editorPhotoBothWeb', {
                parent: 'entity',
                url: '/{storeId}/print/{pid}/{vid}/{xmlId}/{aid}',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/page/editor-photo-both-web.html',
                        controller: 'EditorPhotoBothWebController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            type: 'add'
                        };
                    }
                }
            })
            .state('editorPhotoBothWebEdit', {
                parent: 'app',
                url: '/{storeId}/{aid}/print',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/page/editor-photo-both-web.html',
                        controller: 'EditorPhotoBothWebController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            type: 'edit'
                        };
                    }
                }
            })
            .state('editorPhotoBothWebEdit1', {
                parent: 'app',
                url: '/{storeId}/{orderArticleId}/printOrder?authorization',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/page/editor-photo-both-web.html',
                        controller: 'EditorPhotoBothWebController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            type: 'edit'
                        };
                    }
                }
            })

            .state('editorPhotoAlbum', {
                parent: 'entity',
                url: '/{storeId}/photobook/{pid}/{vid}/{xmlId}/{aid}',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/page/editor-photo-album.html',
                        controller: 'EditorPhotoAlbumController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null,
                            type: 'add'
                        };
                    }
                }
            })
            .state('editorPhotoAlbumEdit', {
                parent: 'app',
                url: '/{storeId}/photobook/{aid}',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/page/editor-photo-album.html',
                        controller: 'EditorPhotoAlbumController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null,
                            type: 'edit'
                        };
                    }
                }
            })
            .state('editorPhotoAlbumEdit1', {
                parent: 'app',
                url: '/{storeId}/{orderArticleId}/photobookOrder?authorization',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/page/editor-photo-album.html',
                        controller: 'EditorPhotoAlbumController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null,
                            type: 'edit'
                        };
                    }
                }
            })
        ;
    }

})();
